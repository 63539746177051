// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Modal, ModalHeader, ModalBody, Form, Label, Input } from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { useHistory } from "react-router-dom";
import fire from "../Fire.js";

//Import Icons
import FeatherIcon from "feather-icons-react";
import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init('782596949083299', options);

// Random component
const Completionist = () => <span>You are good to go!</span>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <React.Fragment>

        <ul className="count-down list-unstyled">
          <li id="days" className="count-number list-inline-item m-2">
          380
          <p className="count-head">Days</p>
          </li>
          <li id="hours" className="count-number list-inline-item m-2">
          {hours}
          <p className="count-head">Hours</p>
          </li>
          <li id="mins" className="count-number list-inline-item m-2">
          {minutes}
          <p className="count-head">Hours</p>
          </li>
          <li id="secs" className="count-number list-inline-item m-2">
          {seconds}
          <p className="count-head">Seconds</p>
          </li>
          <li id="end" className="h1"></li>
        </ul>

      </React.Fragment>
    );
  }
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const Comingsoon = props => {
  const user = fire.auth().currentUser;
  let history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isModalOpen, setOpenModal] = React.useState(false);


  const openModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const apply = () => {
    const profileRef = fire.firestore().collection("users").doc(user.uid);

    const businessName = document.getElementById("businessName").value;
    const websiteLink = document.getElementById("websiteLink").value;
    const businessDescription = document.getElementById("businessDescription").value;
    const instagram = document.getElementById("instagram").value;
    // const newProduct = fire.functions().httpsCallable('newProduct');
    // newProduct({ firstName: firstName, price: price, email: user.email, product: product }).then(res => {
    //   console.log(res.data);
    // });

    profileRef.update({
      businessStatus: "Pending verification",
      businessName: businessName,
      businessDescription: businessDescription,
      websiteLink: websiteLink,
      instagram: instagram,
    });

    const newMerchantRequest = fire.functions().httpsCallable('newMerchantRequest');
    newMerchantRequest({ businessName: businessName, websiteLink: websiteLink, email: user.email, businessDescription: businessDescription, instagram: instagram, }).then(res => {
      console.log(res.data);
    });
    ReactPixel.trackSingle('782596949083299', 'BrandApply', {currency: "NGN", value: 1});

    window.open("https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/files%2Fbrand_tutorial%2FHello%2C%20Welcome%20to%20SWHAG.%20Interest%20in%20supplying%20products%20on%20SWHAG.%20The.pdf?alt=media&token=b4128e5c-3b9e-4eab-aa52-4174c1715fea");

    setOpen(true);
    setOpenModal(false);
  };

  return (
    <React.Fragment>
      <section
        className="bg-home d-flex align-items-center"
        style={{ width: "100%", backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fcomingsoon%2Fcomingsoon.jpg?alt=media&token=fe409a16-1fab-4482-b54d-0a0d379a5828"})` }}
      >
        <div className="bg-overlay"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg="8" md="12" className="text-center">
              <div className="text-uppercase title-dark text-white mt-2 mb-4 coming-soon">
                This site is for verified suppliers only
              </div>
              {/* <h4 className="text-light para-desc para-dark mx-auto">
                Pre-order{" "}
                <span className="text-primary fw-bold">
                  S.W.H.A.G
                </span>{" "}
                and get 60% off for your first 6 months.
              </h4>
              <p className="text-light para-desc para-dark mx-auto">
                <del>NGN 6,000</del>{"  "}<span className="text-primary fw-bold">NGN 2,400</span>/month
              </p> */}
              {/* <p className="text-light para-desc para-dark mx-auto">
                Download our app and get started
              </p> */}
            </Col>
          </Row>

          {/* <Row>
            <Col md="12" className="text-center">
              <div id="countdown">
                <Countdown
                  date={Date.now() + 1199658655000}
                  renderer={renderer}
                />
              </div>
            </Col>
          </Row> */}
          <Row>
            {/* <Col md="6" className="text-center">
              <PaystackButton {...componentProps} className="btn btn-primary mt-4">
                Pre Order
              </PaystackButton>
            </Col> */}
            {/* <Col md="12" className="text-center">
              <Link to="#" className="btn btn-primary mt-4" >
                Request for a product
              </Link>
            </Col> */}
            <Col md="12" className="text-center">
              <Link to="#" onClick={openModal} className="btn btn-primary mt-4 me-2" >
                Apply as a verified supplier
              </Link>
              <Link to="#" onClick={() => window.open("https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/files%2Fbrand_tutorial%2FHello%2C%20Welcome%20to%20SWHAG.%20Interest%20in%20supplying%20products%20on%20SWHAG.%20The.pdf?alt=media&token=b4128e5c-3b9e-4eab-aa52-4174c1715fea")} className="btn btn-primary mt-4 me-2" >
                How to become a supplier
              </Link>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="8" md="12" className="text-center">
              <h4 className="text-light para-desc para-dark mx-auto">
                Looking to open your free {" "}
                <span className="text-primary fw-bold">
                  SWHAG
                </span>{" "}
                store as a reseller?
              </h4>
              <p className="text-light para-desc para-dark mx-auto">
                Download our app and get started
              </p>
            </Col>
            <div className="mt-4" style={{display: 'flex', justifyContent: 'center'}}>
              <Link to="//apps.apple.com/gb/app/swhag/id1602909385" target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-2 me-2">
                <i className="uil uil-apple"></i> Apple Store
              </Link>
              <Link to="//play.google.com/store/apps/details?id=co.swhag.app&hl=en_AU&gl=US"  target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-2 me-2">
                <i className="uil uil-google-play"></i> Play Store
              </Link>
            </div>
          </Row>
          <Modal isOpen={isModalOpen} toggle={closeModal} centered size="lg">
            <ModalHeader toggle={closeModal}>Apply as a verified supplier</ModalHeader>

            <ModalBody className="p-4">
              <Container fluid className="px-0">
                <Row>
                <h4 className="card-title">Give us some information about your business</h4>
                <h5 className="card-title">After filling this form you will receive a pdf with further instructions</h5>
                <div className="custom-form mt-3">
                  <div id="message"></div>
                  <Form
                    name="contact-form"
                    id="contact-form"
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Business Name <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="package"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="name"
                            id="businessName"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Business Name:"
                            required
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Website link <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="package"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="name"
                            id="websiteLink"
                            type="text"
                            className="form-control ps-5"
                            placeholder="https://swhag.co:"
                            required
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Business instagram handle <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="package"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="name"
                            id="instagram"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Instagram handle:"
                            required
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Business description <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="package"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <textarea
                            name="name"
                            id="businessDescription"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Business Description:"
                            required
                            rows={3}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} className="text-center">
                        <div className="d-grid">
                          <input
                            id="submit"
                            name="send"
                            className="submitBnt btn btn-primary"
                            value="Submit Application"
                            onClick={apply}
                          />
                        </div>
                        <div id="simple-msg"></div>

                      </Col>
                    </Row>
                  </Form>
                </div>
                </Row>
              </Container>
            </ModalBody>
            {/* </div>
            </div> */}
          </Modal>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Your request has been sent successfully!
            </Alert>
          </Snackbar>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Comingsoon;
