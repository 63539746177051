import React from 'react';
import {
  Container,
  Row,
  Col,
  // Modal,
  // ModalHeader,
  // ModalBody,
  // Label,
  // Input,
  // Form,
} from "reactstrap";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  AreaChart,
  //ResponsiveContainer,
} from "recharts";

import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
// import { alpha } from '@mui/material/styles';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
// import TableSortLabel from '@mui/material/TableSortLabel';
// import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
// import Checkbox from '@mui/material/Checkbox';
// import Divider from '@mui/material/Divider';
// import { visuallyHidden } from '@mui/utils';
// import ToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// import ButtonGroup from '@mui/material/ButtonGroup';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
// import TextField from '@mui/material/TextField';
// import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
// import DesktopDateRangePicker from '@mui/lab/DesktopDateRangePicker';
// import DateRangePicker from '@mui/lab/DateRangePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Comingsoon from './Comingsoon';


import {fetchAnalyticEvents, fetchAllOrders} from './getProducts';
import fire from "../Fire.js";
import { color } from '@mui/system';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function numberSeparator(numb) {
  var str = numb.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
};

function checkIsNan(numb) {
  if (isNaN(numb)) {
    return 0;
  }
  return numb;
};

function useStore() {
  const [store, setStore] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        setStore(Object.assign(doc.data(), {storeId: doc.id,}));
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

function useProfile() {
  const [profile, setProfile] = React.useState({});

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .onSnapshot(document => {
      setProfile(document.data());
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return profile;
};

function SimpleDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Set backup account</DialogTitle>
      
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

const Analytics = (props) => {
  const user = fire.auth().currentUser;
  const classes = useStyles();
  let history = useHistory();
  const store = useStore();
  const profile = useProfile();
  const [products, setProducts] = React.useState([]);

  const [storeViews, setStoreViews] = React.useState([]);
  const [productViews, setProductViews] = React.useState([]);
  const [addToCarts, setAddToCarts] = React.useState([]);
  const [storeViewsNumber, setStoreViewsNumber] = React.useState(0);
  const [productViewsNumber, setProductViewsNumber] = React.useState(0);
  const [addToCartsNumber, setAddToCartsNumber] = React.useState(0);

  const [eventsGraphData, setEventsGraphData] = React.useState([]);
  const [ordersGraphData, setOrdersGraphData] = React.useState([]);

  const [orders, setOrders] = React.useState([]);
  const [startAfter, setStartAfter] = React.useState();
  const [lastOrder, setLastOrder] = React.useState(false);

  const [totalOrders, setTotalOrders] = React.useState(0);
  const [fulfilledOrders, setFulfilledOrders] = React.useState(0);
  const [orderedItems, setOrderedItems] = React.useState(0);
  const [totalSales, setTotalSales] = React.useState(0);
  const [averageOrderValue, setAverageOrderValue] = React.useState(0);

  const [topProducts, setTopProduts] = React.useState([]);
  const  allMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const periods = ["Today", "This week", "This month", "This quarter", "This year", "Last year"];
  const [period, setPeriod] = React.useState("This month");
  const [start, setStart] = React.useState();
  const [end, setEnd] = React.useState();

  const [currentPeriod, setCurrentPeriod] = React.useState("This month");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState([null, null]);

  var totalRevenue = 0;

  React.useEffect(() => {
    const rightNow = new Date(Date.now());
    const todayDate = new Date().getDate();
    const formattedTodayDate = ("0" + todayDate).slice(-2);
    const thisMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
    const thisYear = new Date().getFullYear();
    const today = new Date(thisYear+"-"+thisMonth+"-"+formattedTodayDate);
    const startMonth = new Date(thisYear+"-"+thisMonth+"-01");
    const startYear = new Date(thisYear+"-01-01");
    const startAt = startMonth;
    const endAt = rightNow;

    var thirtyDaysAgo = today;
    thirtyDaysAgo.setDate(today.getDate() - 30);
    const unsubscribe = fire
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("stores")
      .get()
      .then(stores => {
        stores.forEach(store =>{
          setStart(startAt);
          setEnd(endAt);
          getEvents(store.id, startAt, endAt);
          getOrders(store.id, startAt, endAt);
        });
      });

    return unsubscribe;
  }, []);

  const calculateTopProducts = (productEvents, cartEvents) => {
    var allProductEvents = [];
    var allProductId = [];
    var count = 0;
    productEvents.forEach((event, index) => {
      if (allProductId.indexOf(event.product.productId) > -1) {
        allProductEvents.forEach((item) => {
          if(item.product.productId === event.product.productId){
            allProductEvents[item.position] = Object.assign(event, {productViews: (item.productViews + 1), addToCarts: (item.addToCarts), position: item.position, totalEvents: ( item.totalEvents + 1),});
          };
        });
      } else {
        allProductEvents.push(Object.assign(event, {productViews: 1, addToCarts: 0, position: count, totalEvents: 1,}));
        allProductId.push(event.product.productId);
        count += 1;
      };
    });
    cartEvents.forEach((event) => {
      if (allProductId.indexOf(event.product.productId) > -1) {
        allProductEvents.forEach((item) => {
          if(item.product.productId === event.product.productId){
            allProductEvents[item.position] = Object.assign(event, {productViews: (item.productViews), addToCarts: (item.addToCarts + 1), position: item.position, totalEvents: ( item.totalEvents + 1)});
          };
        });
      } else {
        allProductEvents.push(Object.assign(event, {productViews: 0, addToCarts: 1, position: count, totalEvents: 1,}));
        allProductId.push(event.product.productId);
        count += 1;
      };
    });

    allProductEvents.sort((a, b) => (b.totalEvents > a.totalEvents) ? 1 : -1);
    setTopProduts(allProductEvents);
  };

  const updateGraphData = () => {

    var janStoreViewsCount = 0;
    var janProductViewsCount = 0;
    var janAddToCartsCount = 0;

    var febStoreViewsCount = 0;
    var febProductViewsCount = 0;
    var febAddToCartsCount = 0;

    var marStoreViewsCount = 0;
    var marProductViewsCount = 0;
    var marAddToCartsCount = 0;

    var aprStoreViewsCount = 0;
    var aprProductViewsCount = 0;
    var aprAddToCartsCount = 0;

    var mayStoreViewsCount = 0;
    var mayProductViewsCount = 0;
    var mayAddToCartsCount = 0;

    var junStoreViewsCount = 0;
    var junProductViewsCount = 0;
    var junAddToCartsCount = 0;

    var julStoreViewsCount = 0;
    var julProductViewsCount = 0;
    var julAddToCartsCount = 0;

    var augStoreViewsCount = 0;
    var augProductViewsCount = 0;
    var augAddToCartsCount = 0;

    var sepStoreViewsCount = 0;
    var sepProductViewsCount = 0;
    var sepAddToCartsCount = 0;

    var octStoreViewsCount = 0;
    var octProductViewsCount = 0;
    var octAddToCartsCount = 0;

    var novStoreViewsCount = 0;
    var novProductViewsCount = 0;
    var novAddToCartsCount = 0;

    var decStoreViewsCount = 0;
    var decProductViewsCount = 0;
    var decAddToCartsCount = 0;

    const rightNow = new Date(Date.now());
    const todayDate = new Date().getDate();
    const formattedTodayDate = ("0" + todayDate).slice(-2);
    const thisMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
    const thisYear = new Date().getFullYear();
    const today = new Date(thisYear+"-"+thisMonth+"-"+formattedTodayDate);
    const startMonth = new Date(thisYear+"-"+thisMonth+"-01");
    const startYear = new Date(thisYear+"-01-01");
    const startLastYear = new Date((thisYear-1)+"-01-01");
    const endLastYear = new Date(thisYear+"-01-01");

    if(currentPeriod === "Today"){
      setEventsGraphData([]);
      var timeExtension = "am";
      for(let i = 0; i<24; i++){
        let storeViewsCount = 0;
        let productViewsCount = 0;
        let addToCartsCount = 0;
        
        storeViews.forEach((event) => {
          if ((event.timestamp.toDate().getTime() > new Date(thisYear,new Date().getMonth(),todayDate, i,0,0).getTime()) && (event.timestamp.toDate().getTime() < new Date(thisYear,new Date().getMonth(),todayDate, i+1,0,0).getTime())){
            storeViewsCount += 1;
          }
        });
        productViews.forEach((event) => {
          if ((event.timestamp.toDate().getTime() > new Date(thisYear,new Date().getMonth(),todayDate, i,0,0).getTime()) && (event.timestamp.toDate().getTime() < new Date(thisYear,new Date().getMonth(),todayDate, i+1,0,0).getTime())){
            productViewsCount += 1;
          }
        });
        addToCarts.forEach((event) => {
          if ((event.timestamp.toDate().getTime() > new Date(thisYear,new Date().getMonth(),todayDate, i,0,0).getTime()) && (event.timestamp.toDate().getTime() < new Date(thisYear,new Date().getMonth(),todayDate, i+1,0,0).getTime())){
            addToCartsCount += 1;
          }
        });
        if (i>11) {
          timeExtension = "pm";
          setEventsGraphData(currentEvents => [...currentEvents, { period: i+"pm", storeViewsCount: storeViewsCount, productViewsCount: productViewsCount, addToCartsCount: addToCartsCount}]);
        } else {
          timeExtension = "am";
          setEventsGraphData(currentEvents => [...currentEvents, { period: i+"am", storeViewsCount: storeViewsCount, productViewsCount: productViewsCount, addToCartsCount: addToCartsCount}]);
        }
        //setEventsGraphData(currentEvents => [...currentEvents, { period: i+timeExtension, storeViewsCount: storeViewsCount, productViewsCount: productViewsCount, addToCartsCount: addToCartsCount}]);
      }

    } else if(currentPeriod === "This week") {
      setEventsGraphData([]);
      const currentDay = new Date().getDay();
      var startAt = new Date(thisYear+"-"+thisMonth+"-"+formattedTodayDate);
      startAt.setDate(today.getDate() - currentDay);
      var endAt = new Date(thisYear+"-"+thisMonth+"-"+formattedTodayDate);
      endAt.setDate(today.getDate()- currentDay + 6)

      const monthName = (allMonths[startAt.getMonth()]).substring(0, 3);

      for (let i = startAt.getDate(); i <= endAt.getDate();i++) {
        let storeViewsCount = 0;
        let productViewsCount = 0;
        let addToCartsCount = 0;
        storeViews.forEach((event) => {
          if ((event.timestamp.toDate().getTime() > new Date(thisYear,new Date().getMonth(),i).getTime()) && (event.timestamp.toDate().getTime() < new Date(thisYear,new Date().getMonth(),i+1).getTime())){
            storeViewsCount += 1;
          }
        });
        productViews.forEach((event) => {
          if ((event.timestamp.toDate().getTime() > new Date(thisYear,new Date().getMonth(),i).getTime()) && (event.timestamp.toDate().getTime() < new Date(thisYear,new Date().getMonth(),i+1).getTime())){
            productViewsCount += 1;
          }
        });
        addToCarts.forEach((event) => {
          if ((event.timestamp.toDate().getTime() > new Date(thisYear,new Date().getMonth(),i).getTime()) && (event.timestamp.toDate().getTime() < new Date(thisYear,new Date().getMonth(),i+1).getTime())){
            addToCartsCount += 1;
          }
        });
        setEventsGraphData(currentEvents => [...currentEvents, { period: i+monthName, storeViewsCount: storeViewsCount, productViewsCount: productViewsCount, addToCartsCount: addToCartsCount}]);
      }
      

    } else if(currentPeriod === "This month"){
      const numberOfDays = new Date(thisYear, thisMonth, 0).getDate();

      setEventsGraphData([]);

      for (let i = 1; i <= numberOfDays;i++) {
        let storeViewsCount = 0;
        let productViewsCount = 0;
        let addToCartsCount = 0;
        storeViews.forEach((event) => {
          if ((event.timestamp.toDate().getTime() > new Date(thisYear,new Date().getMonth(),i).getTime()) && (event.timestamp.toDate().getTime() < new Date(thisYear,new Date().getMonth(),i+1).getTime())){
            storeViewsCount += 1;
          }
        });
        productViews.forEach((event) => {
          if ((event.timestamp.toDate().getTime() > new Date(thisYear,new Date().getMonth(),i).getTime()) && (event.timestamp.toDate().getTime() < new Date(thisYear,new Date().getMonth(),i+1).getTime())){
            productViewsCount += 1;
          }
        });
        addToCarts.forEach((event) => {
          if ((event.timestamp.toDate().getTime() > new Date(thisYear,new Date().getMonth(),i).getTime()) && (event.timestamp.toDate().getTime() < new Date(thisYear,new Date().getMonth(),i+1).getTime())){
            addToCartsCount += 1;
          }
        });
        setEventsGraphData(currentEvents => [...currentEvents, { period: i, storeViewsCount: storeViewsCount, productViewsCount: productViewsCount, addToCartsCount: addToCartsCount}]);
      }


    } else if(currentPeriod === "This quarter"){
      setEventsGraphData([]);

      const startJan = new Date((thisYear)+"-01-01");
      const startFeb = new Date((thisYear)+"-02-01");
      const startMar = new Date((thisYear)+"-03-01");
      const startApr = new Date((thisYear)+"-04-01");
      const startMay = new Date((thisYear)+"-05-01");
      const startJun = new Date((thisYear)+"-06-01");
      const startJul = new Date((thisYear)+"-07-01");
      const startAug = new Date((thisYear)+"-08-01");
      const startSep = new Date((thisYear)+"-09-01");
      const startOct = new Date((thisYear)+"-10-01");
      const startNov = new Date((thisYear)+"-11-01");
      const startDec = new Date((thisYear)+"-12-01");
      
      storeViews.forEach((event) => {
        
        if ((event.timestamp.toDate().getTime() > startJan.getTime()) && (event.timestamp.toDate().getTime() < startFeb.getTime())){
          janStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startFeb.getTime()) && (event.timestamp.toDate().getTime() < startMar.getTime())) {
          febStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMar.getTime()) && (event.timestamp.toDate().getTime() < startApr.getTime())) {
          marStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startApr.getTime()) && (event.timestamp.toDate().getTime() < startMay.getTime())) {
          aprStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMay.getTime()) && (event.timestamp.toDate().getTime() < startJun.getTime())) {
          mayStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJun.getTime()) && (event.timestamp.toDate().getTime() < startJul.getTime())) {
          junStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJul.getTime()) && (event.timestamp.toDate().getTime() < startAug.getTime())) {
          julStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startAug.getTime()) && (event.timestamp.toDate().getTime() < startSep.getTime())) {
          augStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startSep.getTime()) && (event.timestamp.toDate().getTime() < startOct.getTime())) {
          sepStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startOct.getTime()) && (event.timestamp.toDate().getTime() < startNov.getTime())) {
          octStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startNov.getTime()) && (event.timestamp.toDate().getTime() < startDec.getTime())) {
          novStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startDec.getTime()) && (event.timestamp.toDate().getTime() < new Date((thisYear+1)+"-01-01").getTime())) {
          decStoreViewsCount +=1 ;
        }
      });

      productViews.forEach((event) => {
  
        if ((event.timestamp.toDate().getTime() > startJan.getTime()) && (event.timestamp.toDate().getTime() < startFeb.getTime())){
          janProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startFeb.getTime()) && (event.timestamp.toDate().getTime() < startMar.getTime())) {
          febProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMar.getTime()) && (event.timestamp.toDate().getTime() < startApr.getTime())) {
          marProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startApr.getTime()) && (event.timestamp.toDate().getTime() < startMay.getTime())) {
          aprProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMay.getTime()) && (event.timestamp.toDate().getTime() < startJun.getTime())) {
          mayProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJun.getTime()) && (event.timestamp.toDate().getTime() < startJul.getTime())) {
          junProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJul.getTime()) && (event.timestamp.toDate().getTime() < startAug.getTime())) {
          julProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startAug.getTime()) && (event.timestamp.toDate().getTime() < startSep.getTime())) {
          augProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startSep.getTime()) && (event.timestamp.toDate().getTime() < startOct.getTime())) {
          sepProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startOct.getTime()) && (event.timestamp.toDate().getTime() < startNov.getTime())) {
          octProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startNov.getTime()) && (event.timestamp.toDate().getTime() < startDec.getTime())) {
          novProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startDec.getTime()) && (event.timestamp.toDate().getTime() < new Date((thisYear+1)+"-01-01").getTime())) {
          decProductViewsCount +=1 ;
        }
      });

      addToCarts.forEach((event) => {
        
        if ((event.timestamp.toDate().getTime() > startJan.getTime()) && (event.timestamp.toDate().getTime() < startFeb.getTime())){
          janAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startFeb.getTime()) && (event.timestamp.toDate().getTime() < startMar.getTime())) {
          febAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMar.getTime()) && (event.timestamp.toDate().getTime() < startApr.getTime())) {
          marAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startApr.getTime()) && (event.timestamp.toDate().getTime() < startMay.getTime())) {
          aprAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMay.getTime()) && (event.timestamp.toDate().getTime() < startJun.getTime())) {
          mayAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJun.getTime()) && (event.timestamp.toDate().getTime() < startJul.getTime())) {
          junAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJul.getTime()) && (event.timestamp.toDate().getTime() < startAug.getTime())) {
          julAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startAug.getTime()) && (event.timestamp.toDate().getTime() < startSep.getTime())) {
          augAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startSep.getTime()) && (event.timestamp.toDate().getTime() < startOct.getTime())) {
          sepAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startOct.getTime()) && (event.timestamp.toDate().getTime() < startNov.getTime())) {
          octAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startNov.getTime()) && (event.timestamp.toDate().getTime() < startDec.getTime())) {
          novAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startDec.getTime()) && (event.timestamp.toDate().getTime() < new Date((thisYear+1)+"-01-01").getTime())) {
          decAddToCartsCount +=1 ;
        }
      });
      if (thisMonth === "01" || thisMonth === "02" || thisMonth === "03"){
        setEventsGraphData([
          {
            period: "Jan",
            storeViewsCount: janStoreViewsCount,
            productViewsCount: janProductViewsCount,
            addToCartsCount: janAddToCartsCount,
          },
          {
            period: "Feb",
            storeViewsCount: febStoreViewsCount,
            productViewsCount: febProductViewsCount,
            addToCartsCount: febAddToCartsCount,
          },
          {
            period: "Mar",
            storeViewsCount: marStoreViewsCount,
            productViewsCount: marProductViewsCount,
            addToCartsCount: marAddToCartsCount,
          },
        ]);        
      } else if (thisMonth === "04" || thisMonth === "05" || thisMonth === "06"){
        setEventsGraphData([
          {
            period: "Apr",
            storeViewsCount: aprStoreViewsCount,
            productViewsCount: aprProductViewsCount,
            addToCartsCount: aprAddToCartsCount,
          },
          {
            period: "May",
            storeViewsCount: mayStoreViewsCount,
            productViewsCount: mayProductViewsCount,
            addToCartsCount: mayAddToCartsCount,
          },
          {
            period: "Jun",
            storeViewsCount: junStoreViewsCount,
            productViewsCount: junProductViewsCount,
            addToCartsCount: junAddToCartsCount,
          },
        ])
      } else if (thisMonth === "07" || thisMonth === "08" || thisMonth === "09"){
        setEventsGraphData([
          {
            period: "Jul",
            storeViewsCount: julStoreViewsCount,
            productViewsCount: julProductViewsCount,
            addToCartsCount: julAddToCartsCount,
          },
          {
            period: "Aug",
            storeViewsCount: augStoreViewsCount,
            productViewsCount: augProductViewsCount,
            addToCartsCount: augAddToCartsCount,
          },
          {
            period: "Sep",
            storeViewsCount: sepStoreViewsCount,
            productViewsCount: sepProductViewsCount,
            addToCartsCount: sepAddToCartsCount,
          },
        ])
      } else if (thisMonth === "10" || thisMonth === "11" || thisMonth === "12"){
        setEventsGraphData([
          {
            period: "Oct",
            storeViewsCount: octStoreViewsCount,
            productViewsCount: octProductViewsCount,
            addToCartsCount: octAddToCartsCount,
          },
          {
            period: "Nov",
            storeViewsCount: novStoreViewsCount,
            productViewsCount: novProductViewsCount,
            addToCartsCount: novAddToCartsCount,
          },
          {
            period: "Dec",
            storeViewsCount: decStoreViewsCount,
            productViewsCount: decProductViewsCount,
            addToCartsCount: decAddToCartsCount,
          },
        ])
      };
    } else if(currentPeriod === "This year") {

      const startJan = new Date((thisYear)+"-01-01");
      const startFeb = new Date((thisYear)+"-02-01");
      const startMar = new Date((thisYear)+"-03-01");
      const startApr = new Date((thisYear)+"-04-01");
      const startMay = new Date((thisYear)+"-05-01");
      const startJun = new Date((thisYear)+"-06-01");
      const startJul = new Date((thisYear)+"-07-01");
      const startAug = new Date((thisYear)+"-08-01");
      const startSep = new Date((thisYear)+"-09-01");
      const startOct = new Date((thisYear)+"-10-01");
      const startNov = new Date((thisYear)+"-11-01");
      const startDec = new Date((thisYear)+"-12-01");
      
      storeViews.forEach((event) => {
        
        if ((event.timestamp.toDate().getTime() > startJan.getTime()) && (event.timestamp.toDate().getTime() < startFeb.getTime())){
          janStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startFeb.getTime()) && (event.timestamp.toDate().getTime() < startMar.getTime())) {
          febStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMar.getTime()) && (event.timestamp.toDate().getTime() < startApr.getTime())) {
          marStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startApr.getTime()) && (event.timestamp.toDate().getTime() < startMay.getTime())) {
          aprStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMay.getTime()) && (event.timestamp.toDate().getTime() < startJun.getTime())) {
          mayStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJun.getTime()) && (event.timestamp.toDate().getTime() < startJul.getTime())) {
          junStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJul.getTime()) && (event.timestamp.toDate().getTime() < startAug.getTime())) {
          julStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startAug.getTime()) && (event.timestamp.toDate().getTime() < startSep.getTime())) {
          augStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startSep.getTime()) && (event.timestamp.toDate().getTime() < startOct.getTime())) {
          sepStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startOct.getTime()) && (event.timestamp.toDate().getTime() < startNov.getTime())) {
          octStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startNov.getTime()) && (event.timestamp.toDate().getTime() < startDec.getTime())) {
          novStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startDec.getTime()) && (event.timestamp.toDate().getTime() < new Date((thisYear+1)+"-01-01").getTime())) {
          decStoreViewsCount +=1 ;
        }
      });

      productViews.forEach((event) => {
  
        if ((event.timestamp.toDate().getTime() > startJan.getTime()) && (event.timestamp.toDate().getTime() < startFeb.getTime())){
          janProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startFeb.getTime()) && (event.timestamp.toDate().getTime() < startMar.getTime())) {
          febProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMar.getTime()) && (event.timestamp.toDate().getTime() < startApr.getTime())) {
          marProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startApr.getTime()) && (event.timestamp.toDate().getTime() < startMay.getTime())) {
          aprProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMay.getTime()) && (event.timestamp.toDate().getTime() < startJun.getTime())) {
          mayProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJun.getTime()) && (event.timestamp.toDate().getTime() < startJul.getTime())) {
          junProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJul.getTime()) && (event.timestamp.toDate().getTime() < startAug.getTime())) {
          julProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startAug.getTime()) && (event.timestamp.toDate().getTime() < startSep.getTime())) {
          augProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startSep.getTime()) && (event.timestamp.toDate().getTime() < startOct.getTime())) {
          sepProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startOct.getTime()) && (event.timestamp.toDate().getTime() < startNov.getTime())) {
          octProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startNov.getTime()) && (event.timestamp.toDate().getTime() < startDec.getTime())) {
          novProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startDec.getTime()) && (event.timestamp.toDate().getTime() < new Date((thisYear+1)+"-01-01").getTime())) {
          decProductViewsCount +=1 ;
        }
      });

      addToCarts.forEach((event) => {
        
        if ((event.timestamp.toDate().getTime() > startJan.getTime()) && (event.timestamp.toDate().getTime() < startFeb.getTime())){
          janAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startFeb.getTime()) && (event.timestamp.toDate().getTime() < startMar.getTime())) {
          febAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMar.getTime()) && (event.timestamp.toDate().getTime() < startApr.getTime())) {
          marAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startApr.getTime()) && (event.timestamp.toDate().getTime() < startMay.getTime())) {
          aprAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMay.getTime()) && (event.timestamp.toDate().getTime() < startJun.getTime())) {
          mayAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJun.getTime()) && (event.timestamp.toDate().getTime() < startJul.getTime())) {
          junAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJul.getTime()) && (event.timestamp.toDate().getTime() < startAug.getTime())) {
          julAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startAug.getTime()) && (event.timestamp.toDate().getTime() < startSep.getTime())) {
          augAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startSep.getTime()) && (event.timestamp.toDate().getTime() < startOct.getTime())) {
          sepAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startOct.getTime()) && (event.timestamp.toDate().getTime() < startNov.getTime())) {
          octAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startNov.getTime()) && (event.timestamp.toDate().getTime() < startDec.getTime())) {
          novAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startDec.getTime()) && (event.timestamp.toDate().getTime() < new Date((thisYear+1)+"-01-01").getTime())) {
          decAddToCartsCount +=1 ;
        }
      });

      setEventsGraphData([
        {
          period: "Jan",
          storeViewsCount: janStoreViewsCount,
          productViewsCount: janProductViewsCount,
          addToCartsCount: janAddToCartsCount,
        },
        {
          period: "Feb",
          storeViewsCount: febStoreViewsCount,
          productViewsCount: febProductViewsCount,
          addToCartsCount: febAddToCartsCount,
        },
        {
          period: "Mar",
          storeViewsCount: marStoreViewsCount,
          productViewsCount: marProductViewsCount,
          addToCartsCount: marAddToCartsCount,
        },
        {
          period: "Apr",
          storeViewsCount: aprStoreViewsCount,
          productViewsCount: aprProductViewsCount,
          addToCartsCount: aprAddToCartsCount,
        },
        {
          period: "May",
          storeViewsCount: mayStoreViewsCount,
          productViewsCount: mayProductViewsCount,
          addToCartsCount: mayAddToCartsCount,
        },
        {
          period: "Jun",
          storeViewsCount: junStoreViewsCount,
          productViewsCount: junProductViewsCount,
          addToCartsCount: junAddToCartsCount,
        },
        {
          period: "Jul",
          storeViewsCount: julStoreViewsCount,
          productViewsCount: julProductViewsCount,
          addToCartsCount: julAddToCartsCount,
        },
        {
          period: "Aug",
          storeViewsCount: augStoreViewsCount,
          productViewsCount: augProductViewsCount,
          addToCartsCount: augAddToCartsCount,
        },
        {
          period: "Sep",
          storeViewsCount: sepStoreViewsCount,
          productViewsCount: sepProductViewsCount,
          addToCartsCount: sepAddToCartsCount,
        },
        {
          period: "Oct",
          storeViewsCount: octStoreViewsCount,
          productViewsCount: octProductViewsCount,
          addToCartsCount: octAddToCartsCount,
        },
        {
          period: "Nov",
          storeViewsCount: novStoreViewsCount,
          productViewsCount: novProductViewsCount,
          addToCartsCount: novAddToCartsCount,
        },
        {
          period: "Dec",
          storeViewsCount: decStoreViewsCount,
          productViewsCount: decProductViewsCount,
          addToCartsCount: decAddToCartsCount,
        },
      ])
    } else if(currentPeriod === "Last year") {

      const startJan = new Date((thisYear-1)+"-01-01");
      const startFeb = new Date((thisYear-1)+"-02-01");
      const startMar = new Date((thisYear-1)+"-03-01");
      const startApr = new Date((thisYear-1)+"-04-01");
      const startMay = new Date((thisYear-1)+"-05-01");
      const startJun = new Date((thisYear-1)+"-06-01");
      const startJul = new Date((thisYear-1)+"-07-01");
      const startAug = new Date((thisYear-1)+"-08-01");
      const startSep = new Date((thisYear-1)+"-09-01");
      const startOct = new Date((thisYear-1)+"-10-01");
      const startNov = new Date((thisYear-1)+"-11-01");
      const startDec = new Date((thisYear-1)+"-12-01");
      
      storeViews.forEach((event) => {
  
        if ((event.timestamp.toDate().getTime() > startJan.getTime()) && (event.timestamp.toDate().getTime() < startFeb.getTime())){
          janStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startFeb.getTime()) && (event.timestamp.toDate().getTime() < startMar.getTime())) {
          febStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMar.getTime()) && (event.timestamp.toDate().getTime() < startApr.getTime())) {
          marStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startApr.getTime()) && (event.timestamp.toDate().getTime() < startMay.getTime())) {
          aprStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMay.getTime()) && (event.timestamp.toDate().getTime() < startJun.getTime())) {
          mayStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJun.getTime()) && (event.timestamp.toDate().getTime() < startJul.getTime())) {
          junStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJul.getTime()) && (event.timestamp.toDate().getTime() < startAug.getTime())) {
          julStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startAug.getTime()) && (event.timestamp.toDate().getTime() < startSep.getTime())) {
          augStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startSep.getTime()) && (event.timestamp.toDate().getTime() < startOct.getTime())) {
          sepStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startOct.getTime()) && (event.timestamp.toDate().getTime() < startNov.getTime())) {
          octStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startNov.getTime()) && (event.timestamp.toDate().getTime() < startDec.getTime())) {
          novStoreViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startDec.getTime()) && (event.timestamp.toDate().getTime() < startYear.getTime())) {
          decStoreViewsCount +=1 ;
        }
      });

      productViews.forEach((event) => {
        if ((event.timestamp.toDate().getTime() > startJan.getTime()) && (event.timestamp.toDate().getTime() < startFeb.getTime())){
          janProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startFeb.getTime()) && (event.timestamp.toDate().getTime() < startMar.getTime())) {
          febProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMar.getTime()) && (event.timestamp.toDate().getTime() < startApr.getTime())) {
          marProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startApr.getTime()) && (event.timestamp.toDate().getTime() < startMay.getTime())) {
          aprProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMay.getTime()) && (event.timestamp.toDate().getTime() < startJun.getTime())) {
          mayProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJun.getTime()) && (event.timestamp.toDate().getTime() < startJul.getTime())) {
          junProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJul.getTime()) && (event.timestamp.toDate().getTime() < startAug.getTime())) {
          julProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startAug.getTime()) && (event.timestamp.toDate().getTime() < startSep.getTime())) {
          augProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startSep.getTime()) && (event.timestamp.toDate().getTime() < startOct.getTime())) {
          sepProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startOct.getTime()) && (event.timestamp.toDate().getTime() < startNov.getTime())) {
          octProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startNov.getTime()) && (event.timestamp.toDate().getTime() < startDec.getTime())) {
          novProductViewsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startDec.getTime()) && (event.timestamp.toDate().getTime() < startYear.getTime())) {
          decProductViewsCount +=1 ;
        }
      });

      addToCarts.forEach((event) => {
        
        if ((event.timestamp.toDate().getTime() > startJan.getTime()) && (event.timestamp.toDate().getTime() < startFeb.getTime())){
          janAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startFeb.getTime()) && (event.timestamp.toDate().getTime() < startMar.getTime())) {
          febAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMar.getTime()) && (event.timestamp.toDate().getTime() < startApr.getTime())) {
          marAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startApr.getTime()) && (event.timestamp.toDate().getTime() < startMay.getTime())) {
          aprAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startMay.getTime()) && (event.timestamp.toDate().getTime() < startJun.getTime())) {
          mayAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJun.getTime()) && (event.timestamp.toDate().getTime() < startJul.getTime())) {
          junAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startJul.getTime()) && (event.timestamp.toDate().getTime() < startAug.getTime())) {
          julAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startAug.getTime()) && (event.timestamp.toDate().getTime() < startSep.getTime())) {
          augAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startSep.getTime()) && (event.timestamp.toDate().getTime() < startOct.getTime())) {
          sepAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startOct.getTime()) && (event.timestamp.toDate().getTime() < startNov.getTime())) {
          octAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startNov.getTime()) && (event.timestamp.toDate().getTime() < startDec.getTime())) {
          novAddToCartsCount +=1 ;
        } else if ((event.timestamp.toDate().getTime() > startDec.getTime()) && (event.timestamp.toDate().getTime() < startYear.getTime())) {
          decAddToCartsCount +=1 ;
        }
      });

      setEventsGraphData([
        {
          period: "Jan",
          storeViewsCount: janStoreViewsCount,
          productViewsCount: janProductViewsCount,
          addToCartsCount: janAddToCartsCount,
        },
        {
          period: "Feb",
          storeViewsCount: febStoreViewsCount,
          productViewsCount: febProductViewsCount,
          addToCartsCount: febAddToCartsCount,
        },
        {
          period: "Mar",
          storeViewsCount: marStoreViewsCount,
          productViewsCount: marProductViewsCount,
          addToCartsCount: marAddToCartsCount,
        },
        {
          period: "Apr",
          storeViewsCount: aprStoreViewsCount,
          productViewsCount: aprProductViewsCount,
          addToCartsCount: aprAddToCartsCount,
        },
        {
          period: "May",
          storeViewsCount: mayStoreViewsCount,
          productViewsCount: mayProductViewsCount,
          addToCartsCount: mayAddToCartsCount,
        },
        {
          period: "Jun",
          storeViewsCount: junStoreViewsCount,
          productViewsCount: junProductViewsCount,
          addToCartsCount: junAddToCartsCount,
        },
        {
          period: "Jul",
          storeViewsCount: julStoreViewsCount,
          productViewsCount: julProductViewsCount,
          addToCartsCount: julAddToCartsCount,
        },
        {
          period: "Aug",
          storeViewsCount: augStoreViewsCount,
          productViewsCount: augProductViewsCount,
          addToCartsCount: augAddToCartsCount,
        },
        {
          period: "Sep",
          storeViewsCount: sepStoreViewsCount,
          productViewsCount: sepProductViewsCount,
          addToCartsCount: sepAddToCartsCount,
        },
        {
          period: "Oct",
          storeViewsCount: octStoreViewsCount,
          productViewsCount: octProductViewsCount,
          addToCartsCount: octAddToCartsCount,
        },
        {
          period: "Nov",
          storeViewsCount: novStoreViewsCount,
          productViewsCount: novProductViewsCount,
          addToCartsCount: novAddToCartsCount,
        },
        {
          period: "Dec",
          storeViewsCount: decStoreViewsCount,
          productViewsCount: decProductViewsCount,
          addToCartsCount: decAddToCartsCount,
        },
      ])
    }
  };

  const updateGraphDataOrders = () => {
    var janSalesCount = 0;
    var janOrdersCount = 0;

    var febSalesCount = 0;
    var febOrdersCount = 0;

    var marSalesCount = 0;
    var marOrdersCount = 0;

    var aprSalesCount = 0;
    var aprOrdersCount = 0;

    var maySalesCount = 0;
    var mayOrdersCount = 0;

    var junSalesCount = 0;
    var junOrdersCount = 0;

    var julSalesCount = 0;
    var julOrdersCount = 0;

    var augSalesCount = 0;
    var augOrdersCount = 0;

    var sepSalesCount = 0;
    var sepOrdersCount = 0;

    var octSalesCount = 0;
    var octOrdersCount = 0;

    var novSalesCount = 0;
    var novOrdersCount = 0;

    var decSalesCount = 0;
    var decOrdersCount = 0;

    const rightNow = new Date(Date.now());
    const todayDate = new Date().getDate();
    const formattedTodayDate = ("0" + todayDate).slice(-2);
    const thisMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
    const thisYear = new Date().getFullYear();
    const today = new Date(thisYear+"-"+thisMonth+"-"+formattedTodayDate);
    const startMonth = new Date(thisYear+"-"+thisMonth+"-01");
    const startYear = new Date(thisYear+"-01-01");
    const startLastYear = new Date((thisYear-1)+"-01-01");
    const endLastYear = new Date(thisYear+"-01-01");

    if(currentPeriod === "Today"){
      setOrdersGraphData([]);
      var timeExtension = "am";
      for(let i = 0; i<24; i++){
        var salesCount = 0;
        var ordersCount = 0;
        if (i>11) {
          timeExtension = "pm";
        }
        orders.forEach((event) => {
          var revenue = 0;
          var productCost = 0;
          var deliveryFee = 0;
          var storeDeliveryFee = 0;
          event.products.forEach(product => {
            if(product.dropshipping === true){
              revenue = revenue + Number(product.price);
              deliveryFee = deliveryFee + Number(product.deliveryFee);
              productCost = productCost + Number(product.cost);
            } else {
              revenue = revenue + Number(product.cost);
              storeDeliveryFee = Number(store.deliveryFee);
            };
          });
          revenue += Number(event.deliveryFee);

          if ((event.timestamp.toDate().getTime() > new Date(thisYear,new Date().getMonth(),todayDate, i,0,0).getTime()) && (event.timestamp.toDate().getTime() < new Date(thisYear,new Date().getMonth(),todayDate, i+1,0,0).getTime())){
            salesCount +=revenue ;
            ordersCount +=1;
          }
        });
        setOrdersGraphData(currentOrders => [...currentOrders, { period: i+timeExtension, salesCount: salesCount, ordersCount: ordersCount, avgOrderValue: checkIsNan((salesCount/ordersCount).toFixed(2))}]);
      }

    } else if(currentPeriod === "This week") {
      setOrdersGraphData([]);
      const currentDay = new Date().getDay();
      var startAt = new Date(thisYear+"-"+thisMonth+"-"+formattedTodayDate);
      startAt.setDate(today.getDate() - currentDay);
      var endAt = new Date(thisYear+"-"+thisMonth+"-"+formattedTodayDate);
      endAt.setDate(today.getDate()- currentDay + 6)

      const monthName = (allMonths[startAt.getMonth()]).substring(0, 3);

      for (let i = startAt.getDate(); i <= endAt.getDate();i++) {
        var salesCount = 0;
        var ordersCount = 0;
        orders.forEach((event) => {
          var revenue = 0;
          var productCost = 0;
          var deliveryFee = 0;
          var storeDeliveryFee = 0;
          event.products.forEach(product => {
            if(product.dropshipping === true){
              revenue = revenue + Number(product.price);
              deliveryFee = deliveryFee + Number(product.deliveryFee);
              productCost = productCost + Number(product.cost);
            } else {
              revenue = revenue + Number(product.cost);
              storeDeliveryFee = Number(store.deliveryFee);
            };
          });
          revenue += Number(event.deliveryFee);

          if ((event.timestamp.toDate().getTime() > new Date(thisYear,new Date().getMonth(),i).getTime()) && (event.timestamp.toDate().getTime() < new Date(thisYear,new Date().getMonth(),i+1).getTime())){
            salesCount +=revenue ;
            ordersCount +=1;
          }
        });
        setOrdersGraphData(currentOrders => [...currentOrders, { period: i+monthName, salesCount: salesCount, ordersCount: ordersCount, avgOrderValue: checkIsNan((salesCount/ordersCount).toFixed(2))}]);
      }
      

    } else if(currentPeriod === "This month"){
      const numberOfDays = new Date(thisYear, thisMonth, 0).getDate();

      setOrdersGraphData([]);

      for (let i = 1; i <= numberOfDays;i++) {
        var salesCount = 0;
        var ordersCount = 0;
        orders.forEach((event) => {
          var revenue = 0;
          var productCost = 0;
          var deliveryFee = 0;
          var storeDeliveryFee = 0;
          event.products.forEach(product => {
            if(product.dropshipping === true){
              revenue = revenue + Number(product.price);
              deliveryFee = deliveryFee + Number(product.deliveryFee);
              productCost = productCost + Number(product.cost);
            } else {
              revenue = revenue + Number(product.cost);
              storeDeliveryFee = Number(store.deliveryFee);
            };
          });
          revenue += Number(event.deliveryFee);

          if ((event.timestamp.toDate().getTime() > new Date(thisYear,new Date().getMonth(),i).getTime()) && (event.timestamp.toDate().getTime() < new Date(thisYear,new Date().getMonth(),i+1).getTime())){
            salesCount +=revenue ;
            ordersCount +=1;
          }
        });
        setOrdersGraphData(currentOrders => [...currentOrders, { period: i, salesCount: salesCount, ordersCount: ordersCount, avgOrderValue: checkIsNan((salesCount/ordersCount).toFixed(2))}]);
      }


    } else if(currentPeriod === "This quarter"){
      setOrdersGraphData([]);

      const startJan = new Date((thisYear)+"-01-01");
      const startFeb = new Date((thisYear)+"-02-01");
      const startMar = new Date((thisYear)+"-03-01");
      const startApr = new Date((thisYear)+"-04-01");
      const startMay = new Date((thisYear)+"-05-01");
      const startJun = new Date((thisYear)+"-06-01");
      const startJul = new Date((thisYear)+"-07-01");
      const startAug = new Date((thisYear)+"-08-01");
      const startSep = new Date((thisYear)+"-09-01");
      const startOct = new Date((thisYear)+"-10-01");
      const startNov = new Date((thisYear)+"-11-01");
      const startDec = new Date((thisYear)+"-12-01");
      
      orders.forEach((event) => {
        var revenue = 0;
        var productCost = 0;
        var deliveryFee = 0;
        var storeDeliveryFee = 0;
        event.products.forEach(product => {
          if(product.dropshipping === true){
            revenue = revenue + Number(product.price);
            deliveryFee = deliveryFee + Number(product.deliveryFee);
            productCost = productCost + Number(product.cost);
          } else {
            revenue = revenue + Number(product.cost);
            storeDeliveryFee = Number(store.deliveryFee);
          };
        });

        revenue += Number(event.deliveryFee);
  
        if ((event.timestamp.toDate().getTime() > startJan.getTime()) && (event.timestamp.toDate().getTime() < startFeb.getTime())){
          janSalesCount +=revenue ;
          janOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startFeb.getTime()) && (event.timestamp.toDate().getTime() < startMar.getTime())) {
          febSalesCount +=revenue ;
          febOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startMar.getTime()) && (event.timestamp.toDate().getTime() < startApr.getTime())) {
          marSalesCount +=revenue ;
          marOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startApr.getTime()) && (event.timestamp.toDate().getTime() < startMay.getTime())) {
          aprSalesCount +=revenue ;
          aprOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startMay.getTime()) && (event.timestamp.toDate().getTime() < startJun.getTime())) {
          maySalesCount +=revenue ;
          mayOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startJun.getTime()) && (event.timestamp.toDate().getTime() < startJul.getTime())) {
          junSalesCount +=revenue ;
          junOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startJul.getTime()) && (event.timestamp.toDate().getTime() < startAug.getTime())) {
          julSalesCount +=revenue ;
          julOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startAug.getTime()) && (event.timestamp.toDate().getTime() < startSep.getTime())) {
          augSalesCount +=revenue ;
          augOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startSep.getTime()) && (event.timestamp.toDate().getTime() < startOct.getTime())) {
          sepSalesCount +=revenue ;
          sepOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startOct.getTime()) && (event.timestamp.toDate().getTime() < startNov.getTime())) {
          octSalesCount +=revenue ;
          octOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startNov.getTime()) && (event.timestamp.toDate().getTime() < startDec.getTime())) {
          novSalesCount +=revenue ;
          novOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startDec.getTime()) && (event.timestamp.toDate().getTime() < new Date((thisYear+1)+"-01-01").getTime())) {
          decSalesCount +=revenue ;
          decOrdersCount +=1;
        }
      });

      if (thisMonth === "01" || thisMonth === "02" || thisMonth === "03"){
        setOrdersGraphData([
          {
            period: "Jan",
            salesCount: janSalesCount,
            ordersCount: janOrdersCount,
            avgOrderValue: checkIsNan((janSalesCount/janOrdersCount).toFixed(2)),
          },
          {
            period: "Feb",
            salesCount: febSalesCount,
            ordersCount: febOrdersCount,
            avgOrderValue: checkIsNan((febSalesCount/febOrdersCount).toFixed(2)),
          },
          {
            period: "Mar",
            salesCount: marSalesCount,
            ordersCount: marOrdersCount,
            avgOrderValue: checkIsNan((marSalesCount/marOrdersCount).toFixed(2)),
          },
        ]);        
      } else if (thisMonth === "04" || thisMonth === "05" || thisMonth === "06"){
        setOrdersGraphData([
          {
            period: "Apr",
            salesCount: aprSalesCount,
            ordersCount: aprOrdersCount,
            avgOrderValue: checkIsNan((aprSalesCount/aprOrdersCount).toFixed(2)),
          },
          {
            period: "May",
            salesCount: maySalesCount,
            ordersCount: mayOrdersCount,
            avgOrderValue: checkIsNan((maySalesCount/mayOrdersCount).toFixed(2)),
          },
          {
            period: "Jun",
            salesCount: junSalesCount,
            ordersCount: junOrdersCount,
            avgOrderValue: checkIsNan((junSalesCount/junOrdersCount).toFixed(2)),
          },
        ])
      } else if (thisMonth === "07" || thisMonth === "08" || thisMonth === "09"){
        setOrdersGraphData([
          {
            period: "Jul",
            salesCount: julSalesCount,
            ordersCount: julOrdersCount,
            avgOrderValue: checkIsNan((julSalesCount/julOrdersCount).toFixed(2)),
          },
          {
            period: "Aug",
            salesCount: augSalesCount,
            ordersCount: augOrdersCount,
            avgOrderValue: checkIsNan((augSalesCount/augOrdersCount).toFixed(2)),
          },
          {
            period: "Sep",
            salesCount: sepSalesCount,
            ordersCount: sepOrdersCount,
            avgOrderValue: checkIsNan((sepSalesCount/sepOrdersCount).toFixed(2)),
          },
        ])
      } else if (thisMonth === "10" || thisMonth === "11" || thisMonth === "12"){
        setOrdersGraphData([
          {
            period: "Oct",
            salesCount: octSalesCount,
            ordersCount: octOrdersCount,
            avgOrderValue: checkIsNan((octSalesCount/octOrdersCount).toFixed(2)),
          },
          {
            period: "Nov",
            salesCount: novSalesCount,
            ordersCount: novOrdersCount,
            avgOrderValue: checkIsNan((novSalesCount/novOrdersCount).toFixed(2)),
          },
          {
            period: "Dec",
            salesCount: decSalesCount,
            ordersCount: decOrdersCount,
            avgOrderValue: checkIsNan((decSalesCount/decOrdersCount).toFixed(2)),
          },
        ])
      };
    } else if(currentPeriod === "This year") {

      const startJan = new Date((thisYear)+"-01-01");
      const startFeb = new Date((thisYear)+"-02-01");
      const startMar = new Date((thisYear)+"-03-01");
      const startApr = new Date((thisYear)+"-04-01");
      const startMay = new Date((thisYear)+"-05-01");
      const startJun = new Date((thisYear)+"-06-01");
      const startJul = new Date((thisYear)+"-07-01");
      const startAug = new Date((thisYear)+"-08-01");
      const startSep = new Date((thisYear)+"-09-01");
      const startOct = new Date((thisYear)+"-10-01");
      const startNov = new Date((thisYear)+"-11-01");
      const startDec = new Date((thisYear)+"-12-01");

      orders.forEach((event) => {
        var revenue = 0;
        var productCost = 0;
        var deliveryFee = 0;
        var storeDeliveryFee = 0;
        event.products.forEach(product => {
          if(product.dropshipping === true){
            revenue = revenue + Number(product.price);
            deliveryFee = deliveryFee + Number(product.deliveryFee);
            productCost = productCost + Number(product.cost);
          } else {
            revenue = revenue + Number(product.cost);
            storeDeliveryFee = Number(store.deliveryFee);
          };
        });
        revenue += Number(event.deliveryFee);
  
        if ((event.timestamp.toDate().getTime() > startJan.getTime()) && (event.timestamp.toDate().getTime() < startFeb.getTime())){
          janSalesCount +=revenue ;
          janOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startFeb.getTime()) && (event.timestamp.toDate().getTime() < startMar.getTime())) {
          febSalesCount +=revenue ;
          febOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startMar.getTime()) && (event.timestamp.toDate().getTime() < startApr.getTime())) {
          marSalesCount +=revenue ;
          marOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startApr.getTime()) && (event.timestamp.toDate().getTime() < startMay.getTime())) {
          aprSalesCount +=revenue ;
          aprOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startMay.getTime()) && (event.timestamp.toDate().getTime() < startJun.getTime())) {
          maySalesCount +=revenue ;
          mayOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startJun.getTime()) && (event.timestamp.toDate().getTime() < startJul.getTime())) {
          junSalesCount +=revenue ;
          junOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startJul.getTime()) && (event.timestamp.toDate().getTime() < startAug.getTime())) {
          julSalesCount +=revenue ;
          julOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startAug.getTime()) && (event.timestamp.toDate().getTime() < startSep.getTime())) {
          augSalesCount +=revenue ;
          augOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startSep.getTime()) && (event.timestamp.toDate().getTime() < startOct.getTime())) {
          sepSalesCount +=revenue ;
          sepOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startOct.getTime()) && (event.timestamp.toDate().getTime() < startNov.getTime())) {
          octSalesCount +=revenue ;
          octOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startNov.getTime()) && (event.timestamp.toDate().getTime() < startDec.getTime())) {
          novSalesCount +=revenue ;
          novOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startDec.getTime()) && (event.timestamp.toDate().getTime() < new Date((thisYear+1)+"-01-01").getTime())) {
          decSalesCount +=revenue ;
          decOrdersCount +=1;
        }
      });

      setOrdersGraphData([
        {
          period: "Jan",
          salesCount: janSalesCount,
          ordersCount: janOrdersCount,
          avgOrderValue: checkIsNan((janSalesCount/janOrdersCount).toFixed(2)),
        },
        {
          period: "Feb",
          salesCount: febSalesCount,
          ordersCount: febOrdersCount,
          avgOrderValue: checkIsNan((febSalesCount/febOrdersCount).toFixed(2)),
        },
        {
          period: "Mar",
          salesCount: marSalesCount,
          ordersCount: marOrdersCount,
          avgOrderValue: checkIsNan((marSalesCount/marOrdersCount).toFixed(2)),
        },
        {
          period: "Apr",
          salesCount: aprSalesCount,
          ordersCount: aprOrdersCount,
          avgOrderValue: checkIsNan((aprSalesCount/aprOrdersCount).toFixed(2)),
        },
        {
          period: "May",
          salesCount: maySalesCount,
          ordersCount: mayOrdersCount,
          avgOrderValue: checkIsNan((maySalesCount/mayOrdersCount).toFixed(2)),
        },
        {
          period: "Jun",
          salesCount: junSalesCount,
          ordersCount: junOrdersCount,
          avgOrderValue: checkIsNan((junSalesCount/junOrdersCount).toFixed(2)),
        },
        {
          period: "Jul",
          salesCount: julSalesCount,
          ordersCount: julOrdersCount,
          avgOrderValue: checkIsNan((julSalesCount/julOrdersCount).toFixed(2)),
        },
        {
          period: "Aug",
          salesCount: augSalesCount,
          ordersCount: augOrdersCount,
          avgOrderValue: checkIsNan((augSalesCount/augOrdersCount).toFixed(2)),
        },
        {
          period: "Sep",
          salesCount: sepSalesCount,
          ordersCount: sepOrdersCount,
          avgOrderValue: checkIsNan((sepSalesCount/sepOrdersCount).toFixed(2)),
        },
        {
          period: "Oct",
          salesCount: octSalesCount,
          ordersCount: octOrdersCount,
          avgOrderValue: checkIsNan((octSalesCount/octOrdersCount).toFixed(2)),
        },
        {
          period: "Nov",
          salesCount: novSalesCount,
          ordersCount: novOrdersCount,
          avgOrderValue: checkIsNan((novSalesCount/novOrdersCount).toFixed(2)),
        },
        {
          period: "Dec",
          salesCount: decSalesCount,
          ordersCount: decOrdersCount,
          avgOrderValue: checkIsNan((decSalesCount/decOrdersCount).toFixed(2)),
        },
      ]);

    } else if(currentPeriod === "Last year") {

      const startJan = new Date((thisYear-1)+"-01-01");
      const startFeb = new Date((thisYear-1)+"-02-01");
      const startMar = new Date((thisYear-1)+"-03-01");
      const startApr = new Date((thisYear-1)+"-04-01");
      const startMay = new Date((thisYear-1)+"-05-01");
      const startJun = new Date((thisYear-1)+"-06-01");
      const startJul = new Date((thisYear-1)+"-07-01");
      const startAug = new Date((thisYear-1)+"-08-01");
      const startSep = new Date((thisYear-1)+"-09-01");
      const startOct = new Date((thisYear-1)+"-10-01");
      const startNov = new Date((thisYear-1)+"-11-01");
      const startDec = new Date((thisYear-1)+"-12-01");
      
      orders.forEach((event) => {
        var revenue = 0;
        var productCost = 0;
        var deliveryFee = 0;
        var storeDeliveryFee = 0;
        event.products.forEach(product => {
          if(product.dropshipping === true){
            revenue = revenue + Number(product.price);
            deliveryFee = deliveryFee + Number(product.deliveryFee);
            productCost = productCost + Number(product.cost);
          } else {
            revenue = revenue + Number(product.cost);
            storeDeliveryFee = Number(store.deliveryFee);
          };
        });
        revenue += Number(event.deliveryFee);
  
        if ((event.timestamp.toDate().getTime() > startJan.getTime()) && (event.timestamp.toDate().getTime() < startFeb.getTime())){
          janSalesCount +=revenue ;
          janOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startFeb.getTime()) && (event.timestamp.toDate().getTime() < startMar.getTime())) {
          febSalesCount +=revenue ;
          febOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startMar.getTime()) && (event.timestamp.toDate().getTime() < startApr.getTime())) {
          marSalesCount +=revenue ;
          marOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startApr.getTime()) && (event.timestamp.toDate().getTime() < startMay.getTime())) {
          aprSalesCount +=revenue ;
          aprOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startMay.getTime()) && (event.timestamp.toDate().getTime() < startJun.getTime())) {
          maySalesCount +=revenue ;
          mayOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startJun.getTime()) && (event.timestamp.toDate().getTime() < startJul.getTime())) {
          junSalesCount +=revenue ;
          junOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startJul.getTime()) && (event.timestamp.toDate().getTime() < startAug.getTime())) {
          julSalesCount +=revenue ;
          julOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startAug.getTime()) && (event.timestamp.toDate().getTime() < startSep.getTime())) {
          augSalesCount +=revenue ;
          augOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startSep.getTime()) && (event.timestamp.toDate().getTime() < startOct.getTime())) {
          sepSalesCount +=revenue ;
          sepOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startOct.getTime()) && (event.timestamp.toDate().getTime() < startNov.getTime())) {
          octSalesCount +=revenue ;
          octOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startNov.getTime()) && (event.timestamp.toDate().getTime() < startDec.getTime())) {
          novSalesCount +=revenue ;
          novOrdersCount +=1;
        } else if ((event.timestamp.toDate().getTime() > startDec.getTime()) && (event.timestamp.toDate().getTime() < startYear.getTime())) {
          decSalesCount +=revenue ;
          decOrdersCount +=1;
        }
      });

      setOrdersGraphData([
        {
          period: "Jan",
          salesCount: janSalesCount,
          ordersCount: janOrdersCount,
          avgOrderValue: checkIsNan((janSalesCount/janOrdersCount).toFixed(2)),
        },
        {
          period: "Feb",
          salesCount: febSalesCount,
          ordersCount: febOrdersCount,
          avgOrderValue: checkIsNan((febSalesCount/febOrdersCount).toFixed(2)),
        },
        {
          period: "Mar",
          salesCount: marSalesCount,
          ordersCount: marOrdersCount,
          avgOrderValue: checkIsNan((marSalesCount/marOrdersCount).toFixed(2)),
        },
        {
          period: "Apr",
          salesCount: aprSalesCount,
          ordersCount: aprOrdersCount,
          avgOrderValue: checkIsNan((aprSalesCount/aprOrdersCount).toFixed(2)),
        },
        {
          period: "May",
          salesCount: maySalesCount,
          ordersCount: mayOrdersCount,
          avgOrderValue: checkIsNan((maySalesCount/mayOrdersCount).toFixed(2)),
        },
        {
          period: "Jun",
          salesCount: junSalesCount,
          ordersCount: junOrdersCount,
          avgOrderValue: checkIsNan((junSalesCount/junOrdersCount).toFixed(2)),
        },
        {
          period: "Jul",
          salesCount: julSalesCount,
          ordersCount: julOrdersCount,
          avgOrderValue: checkIsNan((julSalesCount/julOrdersCount).toFixed(2)),
        },
        {
          period: "Aug",
          salesCount: augSalesCount,
          ordersCount: augOrdersCount,
          avgOrderValue: checkIsNan((augSalesCount/augOrdersCount).toFixed(2)),
        },
        {
          period: "Sep",
          salesCount: sepSalesCount,
          ordersCount: sepOrdersCount,
          avgOrderValue: checkIsNan((sepSalesCount/sepOrdersCount).toFixed(2)),
        },
        {
          period: "Oct",
          salesCount: octSalesCount,
          ordersCount: octOrdersCount,
          avgOrderValue: checkIsNan((octSalesCount/octOrdersCount).toFixed(2)),
        },
        {
          period: "Nov",
          salesCount: novSalesCount,
          ordersCount: novOrdersCount,
          avgOrderValue: checkIsNan((novSalesCount/novOrdersCount).toFixed(2)),
        },
        {
          period: "Dec",
          salesCount: decSalesCount,
          ordersCount: decOrdersCount,
          avgOrderValue: checkIsNan((decSalesCount/decOrdersCount).toFixed(2)),
        },
      ]);
    };
  };

  async function getEvents(storeId, startAt, endAt) {
    const storeViewsData = await  fetchAnalyticEvents({storeId: storeId, userUid: user.uid, dataType: "storeViews", startAt: startAt, endAt: endAt});
    const productViewsData = await fetchAnalyticEvents({storeId: storeId, userUid: user.uid, dataType: "productViews", startAt: startAt, endAt: endAt});
    const addToCartsData = await fetchAnalyticEvents({storeId: storeId, userUid: user.uid, dataType: "addToCarts", startAt: startAt, endAt: endAt});
    setStoreViews([...storeViewsData.events]);
    setProductViews([...productViewsData.events]);
    setAddToCarts([...addToCartsData.events]);
    setStoreViewsNumber(storeViewsData.events.length);
    setProductViewsNumber(productViewsData.events.length);
    setAddToCartsNumber(addToCartsData.events.length);
    calculateTopProducts(productViewsData.events, addToCartsData.events);

    
    //updateGraphData();
  };

  async function getOrders(storeId, startAt, endAt) {

    var totalOrders = 0;
    var totalFulfilledOrders = 0;
    var AllOrders = [];

    setTotalSales(0);
    setOrders([]);
    setTotalOrders(0);
    setFulfilledOrders(0);
    setOrderedItems(0);

    await fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(storeId)
    .collection("orders")
    .where('timestamp', '>', startAt)
    .where('timestamp', '<', endAt)
    .orderBy('timestamp', 'desc')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((order) => {
        const products = [];
        totalOrders += 1;
        if (order.data().status === "Fulfilled") {
          totalFulfilledOrders += 1;
        }
        setTotalSales(totalSales + Number(order.data().deliveryFee));
        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("stores")
        .doc(storeId)
        .collection("orders")
        .doc(order.id)
        .collection("products")
        .get()
        .then(productsOrdered => {
          productsOrdered.forEach(productOrdered => {
            products.push(productOrdered.data());
            setTotalSales(totalSales + Number(productOrdered.data().cost));
          });
          setOrders(currentOrders => [...currentOrders, Object.assign(order.data(), { products: products, orderId: order.id})]);
          setOrderedItems(orderedItems + products.length);
          AllOrders.push(Object.assign(order.data(), { products: products, orderId: order.id}));
        });
      });
    });

    setTotalOrders(totalOrders);
    setFulfilledOrders(totalFulfilledOrders);
    //updateGraphDataOrders();

  };


  const changePeriod = (event) => {
    setPeriod(event.target.value);
    const selectedPeriod = event.target.value;
    setCurrentPeriod(selectedPeriod);

    const rightNow = new Date(Date.now());
    const todayDate = new Date().getDate();
    const formattedTodayDate = ("0" + todayDate).slice(-2);
    const thisMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
    const thisYear = new Date().getFullYear();
    const today = new Date(thisYear+"-"+thisMonth+"-"+formattedTodayDate);
    const startMonth = new Date(thisYear+"-"+thisMonth+"-01");
    const startYear = new Date(thisYear+"-01-01");
    const startLastYear = new Date((thisYear-1)+"-01-01");
    const endLastYear = new Date(thisYear+"-01-01");

    if (selectedPeriod === "Today"){
      const startAt = today;
      const endAt = rightNow;
      getEvents(store.storeId, startAt, endAt );
      getOrders(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "This week") {
      const currentDay = new Date().getDay();
      var startAt = today;
      startAt.setDate(today.getDate() - currentDay);
      const endAt = rightNow;
      setStart(startAt);
      setEnd(endAt);
      getEvents(store.storeId, startAt, endAt );
      getOrders(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "This month") {
      const startAt = startMonth;
      const endAt = rightNow;
      setStart(startAt);
      setEnd(endAt);
      getEvents(store.storeId, startAt, endAt );
      getOrders(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "This quarter") {
      if (thisMonth === "01" || thisMonth === "02" || thisMonth === "03"){
        const startQuarter = new Date(thisYear+"-01-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getEvents(store.storeId, startAt, endAt );
        getOrders(store.storeId, startAt, endAt );
      } else if (thisMonth === "04" || thisMonth === "05" || thisMonth === "06"){
        const startQuarter = new Date(thisYear+"-04-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getEvents(store.storeId, startAt, endAt );
        getOrders(store.storeId, startAt, endAt );
      } else if (thisMonth === "07" || thisMonth === "08" || thisMonth === "09"){
        const startQuarter = new Date(thisYear+"-07-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getEvents(store.storeId, startAt, endAt );
        getOrders(store.storeId, startAt, endAt );
      } else if (thisMonth === "10" || thisMonth === "11" || thisMonth === "12"){
        const startQuarter = new Date(thisYear+"-10-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getEvents(store.storeId, startAt, endAt );
        getOrders(store.storeId, startAt, endAt );
      };
    } else if (selectedPeriod === "This year") {
      const startAt = startYear;
      const endAt = rightNow;
      setStart(startAt);
      setEnd(endAt);
      getEvents(store.storeId, startAt, endAt );
      getOrders(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "Last year") {
      const startAt = startLastYear;
      const endAt = endLastYear;
      setStart(startAt);
      setEnd(endAt);
      getEvents(store.storeId, startAt, endAt );
      getOrders(store.storeId, startAt, endAt );
    }
  };

  const handleClickDate = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDialog = () => {
    setAnchorEl(null);
  };

  const openDateDialog = Boolean(anchorEl);
  const popoverId = openDateDialog ? 'simple-popover' : undefined;

  if(profile.verified !== true){
    return(<Comingsoon/>);
  } else {

  return (
    <React.Fragment>
    <section className="section" style={{width: "100%", flex: 1, height: "100%", minHeight: "100vh", backgroundColor: '#f2f8fb'}}>
    <Container>
      <Typography variant="h3" style={{marginBottom: 10}}>Analytics</Typography>
      {/* <FormControl fullWidth >
        <InputLabel id="demo-simple-select-label">Date range</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={period}
          label="Age"
          onChange={changePeriod}
        >
          {periods.map((item)=> {
            return(
              <MenuItem value={item}>{item}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <Button variant="outlined" onClick={()=> { updateGraphDataOrders(); updateGraphData()}}>
        Apply
      </Button> */}
      <Button variant="outlined" onClick={handleClickDate}>
        {currentPeriod}
      </Button>
      <Popover
        id={popoverId}
        open={openDateDialog}
        anchorEl={anchorEl}
        onClose={handleCloseDialog}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{width: 600, padding: 20,}}>
          <FormControl fullWidth >
            <InputLabel id="demo-simple-select-label">Date range</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={period}
              label="Age"
              onChange={changePeriod}
            >
              {periods.map((item)=> {
                return(
                  <MenuItem value={item}>{item}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <Button variant="outlined" onClick={()=> { updateGraphDataOrders(); updateGraphData()}}>
            Apply
          </Button>
        </div>
      </Popover>
      
      <Row >
        <Col xs={12} md={6} lg={4}>
          <Card sx={{ maxWidth: 345, marginTop: 2 }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Total sales
              </Typography>
              <Typography variant="h3" color="text.secondary">
                NGN {numberSeparator(totalSales)}
              </Typography>
              <AreaChart
                width={300}
                height={300}
                data={ordersGraphData}
                syncId="orders"
                margin={{
                  top: 10,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >

                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="period" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="salesCount"
                  stroke="#2f55d4"
                  fill="#2f55d4"
                />
              </AreaChart>
            </CardContent>
            <CardActions>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 345, marginTop: 2 }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Total Orders
              </Typography>
              <Typography variant="h3" color="text.secondary">
              {numberSeparator(totalOrders)}
              </Typography>
              <AreaChart
                width={300}
                height={300}
                data={ordersGraphData}
                syncId="orders"
                margin={{
                  top: 10,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >

                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="period" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="ordersCount"
                  stroke="#2f55d4"
                  fill="#2f55d4"
                />
              </AreaChart>
            </CardContent>
            <CardActions>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 345, marginTop: 2 }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Total Fulfilled Orders
              </Typography>
              <Typography variant="h3" color="text.secondary">
              {numberSeparator(fulfilledOrders)}
              </Typography>
            </CardContent>
            <CardActions>
            </CardActions>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <Card sx={{ maxWidth: 345, marginTop: 2 }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Online store sessions
              </Typography>
              <Typography variant="h3" color="text.secondary">
                {numberSeparator(storeViewsNumber)}
              </Typography>
            </CardContent>
            <AreaChart
              width={300}
              height={300}
              data={eventsGraphData}
              syncId="events"
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >

              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="period" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area
                type="monotone"
                dataKey="storeViewsCount"
                stroke="#2f55d4"
                fill="#2f55d4"
              />
            </AreaChart>
            <CardActions>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 345, marginTop: 2 }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Average order value
              </Typography>
              <Typography variant="h3" color="text.secondary">
                NGN {numberSeparator(checkIsNan(Math.round(totalSales/totalOrders)))}
              </Typography>
              <AreaChart
                width={300}
                height={300}
                data={ordersGraphData}
                syncId="orders"
                margin={{
                  top: 10,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >

                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="period" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="avgOrderValue"
                  stroke="#2f55d4"
                  fill="#2f55d4"
                />
              </AreaChart>
            </CardContent>
            <CardActions>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 345, marginTop: 2 }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Online store conversion rate
              </Typography>
              <Typography variant="h3" color="text.secondary">
                {checkIsNan(((totalOrders/storeViewsNumber)*100).toFixed(2))}%
              </Typography>
            </CardContent>
            <CardActions>
            </CardActions>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <Card sx={{ maxWidth: 345, marginTop: 2 }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Product views
              </Typography>
              <Typography variant="h3" color="text.secondary">
              {numberSeparator(productViewsNumber)}
              </Typography>
            </CardContent>
            <AreaChart
              width={300}
              height={300}
              data={eventsGraphData}
              syncId="events"
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >

              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="period" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area
                type="monotone"
                dataKey="productViewsCount"
                stroke="#2f55d4"
                fill="#2f55d4"
              />
            </AreaChart>
            <CardActions>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 345, marginTop: 2 }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Add to carts
              </Typography>
              <Typography variant="h3" color="text.secondary">
              {numberSeparator(addToCartsNumber)}
              </Typography>
            </CardContent>
            <AreaChart
              width={300}
              height={300}
              data={eventsGraphData}
              syncId="events"
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >

              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="period" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area
                type="monotone"
                dataKey="addToCartsCount"
                stroke="#2f55d4"
                fill="#2f55d4"
              />
            </AreaChart>
            <CardActions>
            </CardActions>
          </Card>
        </Col>
      </Row>
    </Container>
    </section>
    </React.Fragment>
  );
  }
}

export default Analytics;