import React, { Component, Suspense } from "react";
import Layout from "./components/Layout/";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";

// Import Css
import "./assets/css/materialdesignicons.min.css";
import "./Apps.scss";

import "./assets/css/colors/default.css";

import Dashboard from "./Home.js";
import PageLoginThree from "./pages/Pages/AuthPages/PageLoginThree.js";
import PageSignupThree from "./pages/Pages/AuthPages/PageSignupThree.js";
import ForgotPassword from "./pages/Pages/AuthPages/ForgotPassword.js";

import { ConfirmProvider } from 'material-ui-confirm';
// Include Routes
import fire from "./Fire.js";
import Cohere from "cohere-js";
Cohere.init("_9jjHJoRr-GTiwhMaLRss43C");

function withLayout(WrappedComponent, hasDarkTopBar) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <Layout hasDarkTopBar={hasDarkTopBar}>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
    }

    this.authListener = this.authListener.bind(this);
  }
  componentDidMount() {
    this.authListener();
  }

  authListener() {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
        Cohere.identify(
          user.uid, // Required: can be any unique ID
          {
            email: user.email, // Optional
          }
        );
      } else {
        this.setState({ user: null });
      }
    })
  };

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <ConfirmProvider>
      <React.Fragment>
        <Router>
          <Suspense fallback={this.Loader()}>
            <Switch>
              <Route path="/forgotPassword" >
                <ForgotPassword />
              </Route>
              <Route path="/signUp" >
                <PageSignupThree />
              </Route>
              <Route path="/" >
                {this.state.user ? (<Dashboard />) : (<PageLoginThree />)}
              </Route>
              {/* {routes.map((route, idx) =>
                route.isWithoutLayout ? (
                  <Route
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                    key={idx}
                  />
                ) : (
                  <Route
                    path={route.path}
                    exact
                    component={withLayout(route.component, route.isTopbarDark)}
                    key={idx}
                  />
                )
              )} */}
            </Switch>
          </Suspense>
        </Router>
      </React.Fragment>
      </ConfirmProvider>
    );
  }
}

export default withRouter(App);
