import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database'
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/functions';
// Your web app's Firebase configuration
var firebaseConfig = {
apiKey: "AIzaSyBilc-DSpa8kVrlXhDaH0ZLGoRRfM8PVMY",
authDomain: "swhag-1a9e3.firebaseapp.com",
projectId: "swhag-1a9e3",
storageBucket: "swhag-1a9e3.appspot.com",
messagingSenderId: "935468590993",
appId: "1:935468590993:web:9bed76073b2acd3c2e5c30",
measurementId: "G-4DJ70B42XC"
};
// Initialize Firebase

const fire = firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default fire;
