// React Basic and Bootstrap
import React from "react";
import {
  Container,
  Row,
  Col,
  Input,
  Label,
  CardBody,
} from "reactstrap";
import banks from './Banks.js';
import fire from "../Fire.js";
import firebase from 'firebase';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

import Orders from "./Orders";


//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import MyProducts from "./MyProducts.js";
import EditStore from "./EditStore.js";
//import Pricing from "./Pricing";
//import { useHistory } from "react-router-dom";
import { ConfirmProvider } from 'material-ui-confirm';
import { useConfirm } from 'material-ui-confirm';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

function useAllstores() {
  const [stores, setStores] = React.useState({});

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("stores")
    .onSnapshot(querySnapshot => {
      setStores([]);
      querySnapshot.forEach(store => {
        setStores(currentStores => [...currentStores, store.data()]);
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return stores;
};
function useProfile() {
  const [profile, setProfile] = React.useState({});

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .onSnapshot(document => {
      setProfile(document.data());
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return profile;
};

function useOrders() {
  const [orders, setOrders] = React.useState([]);

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .get()
    .then(stores => {
      stores.forEach(store =>{
        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("stores")
        .doc(store.id)
        .collection("orders")
        .get()
        .then(queryDocumentSnapshot => {
          queryDocumentSnapshot.forEach(order => {
            const products = [];
            fire
            .firestore()
            .collection("users")
            .doc(user.uid)
            .collection("stores")
            .doc(store.id)
            .collection("orders")
            .doc(order.id)
            .collection("products")
            .get()
            .then(productsOrdered => {
              productsOrdered.forEach(productOrdered => {
                products.push(productOrdered.data());
              });
            });
            setOrders(currentOrders => [...currentOrders, Object.assign(order.data(), {products: products, orderId: order.id})]);
          });
        });
      });
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return orders;
};

const Store = props => {
  const classes = useStyles();
  //let history = useHistory();
  const confirm = useConfirm();
  const allStores = useAllstores();
  const profile = useProfile();
  const orders = useOrders();
  const user = fire.auth().currentUser;
  const [storeNameError, setStoreNameError] = React.useState();

  const [value, setValue] = React.useState(0);

  if(profile.store === true){
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <React.Fragment >
        <section className="section" style={{width: "100%"}}>
        <Container >
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" centered variant="fullWidth">
                <Tab label="Store" {...a11yProps(0)} />
                <Tab label="Orders" {...a11yProps(1)} />
                <Tab label="Products" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <EditStore profile={profile} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Orders orders={orders}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <MyProducts />
            </TabPanel>
          </div>
        </Container>
        </section>
      </React.Fragment>
    );

  } else {

    const changeStoreName = () => {
      const storeName = (document.getElementById("storeName").value).toLowerCase();
      if (/\s/.test(storeName)) {
        setStoreNameError(
          <p className=" small fst-italic mb-0 mt-1" style={{color: 'red'}}>
            Don't add any space in your store name. Should be one word
          </p>
          );
      } else {
      setStoreNameError(null);
      };
      allStores.forEach(store => {
        if ((storeName).toLowerCase() === (store.storeName).toLowerCase()) {
          setStoreNameError(
          <p className=" small fst-italic mb-0 mt-1" style={{color: 'red'}}>
            This store name is already taken
          </p>
          );
        } else {
        }
      });
    };

    const createStore = () => {
      const storeName = document.getElementById("storeName").value;
      const firstName = document.getElementById("firstName").value;
      const lastName = document.getElementById("lastName").value;
      const email = user.email;
      const bank = document.getElementById("bank").value;
      const accountNumber = document.getElementById("accountNumber").value;
      const storeRef = fire.firestore().collection("users").doc(user.uid).collection("stores").doc();
      confirm({ description: 'Are you sure you have entered the correct information?', confirmationText: 'Yes' })
      .then(() => { 
        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .update({
          store: true,
          firstName: firstName,
          lastName: lastName,
        });
        
        storeRef.set({
          userUid: user.uid,
          storeId: storeRef.id,
          storeName: storeName,
          storeEmail: email,
          bank: bank,
          accountNumber: accountNumber,
          deliveryFee: Number(1000),
          ctaHeading: "End of Season Clearance Sale upto 40%",
          ctaSubheading: "Best e-commerce platform, find the best products at the best prices.",
          heading: "New Accessories Collections",
          mainImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fbg1.jpg?alt=media&token=c911db86-bfd0-4cb3-9f80-ce3401bed709",
          secondaryImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fcta.jpg?alt=media&token=1e924636-dcb2-4891-8564-44ebdb07c9e1",
          subheading: "Best e-commerce platform, find the best products at the best prices.",
          dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        });
        fire
        .firestore()
        .collection("stores")
        .doc(storeRef.id)
        .set({
          userUid: user.uid,
          storeId: storeRef.id,
          storeName: storeName,
          storeEmail: email,
          bank: bank,
          accountNumber: accountNumber,
          deliveryFee: Number(1000),
          ctaHeading: "End of Season Clearance Sale upto 40%",
          ctaSubheading: "Best e-commerce platform, find the best products at the best prices.",
          heading: "New Accessories Collections",
          mainImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fbg1.jpg?alt=media&token=c911db86-bfd0-4cb3-9f80-ce3401bed709",
          secondaryImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fcta.jpg?alt=media&token=1e924636-dcb2-4891-8564-44ebdb07c9e1",
          subheading: "Best e-commerce platform, find the best products at the best prices.",
          dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        });
        storeRef.collection("orders").doc("--stats--").set({
          orders: 0,
          revenue: 0,
          costs: 0,
          profits: 0,
          deliveryFee: 0,
        });
    
        user.updateProfile({
          displayName: firstName,
        });
        const storeSubaccount = fire.functions().httpsCallable('storeSubaccount');
        storeSubaccount({accountNumber: accountNumber, bank: bank, firstName: firstName, lastName: lastName, uid: user.uid, storeId: storeRef.id}).then(result => {
          console.log(result.data);
        });
        document.getElementById("contact-form").reset();

      })
      .catch(() => { /* ... */ });
      
    };

    // const changePlan = (plan, title) => {
    //   history.push("/Products");
    // };

    return (
      <ConfirmProvider>
      <React.Fragment>
        <section className="section pb-0" style={{width: "100%"}}>

          <Container className="mt-0 mt-0">
            <Row className="align-items-center">
              <Col
                xs={12}
                className="mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1"
              >
                <Card className="shadow rounded border-0">
                  <CardBody className="py-5">
                    <h4 className="card-title">Create your store!</h4>
                    <div className="custom-form mt-3">
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              {storeNameError}
                              <Label className="form-label">
                                Your Store Name <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="user"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="name"
                                id="storeName"
                                type="text"
                                className="form-control ps-5"
                                placeholder="One word e.g 'bookshop' not 'book shop'"
                                required
                                onChange={changeStoreName}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your First Name <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="user"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="name"
                                id="firstName"
                                type="text"
                                className="form-control ps-5"
                                placeholder="First Name :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Last Name <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="user"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="name"
                                id="lastName"
                                type="text"
                                className="form-control ps-5"
                                placeholder="Last Name :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Bank <span className="text-danger">*</span>
                              </Label>
                              <select className="btn btn-icon form-control ps-5 quantity" id="bank" style={{width: "100%"}}>
                                {banks.map((bank) => {
                                  return(
                                    <option key={bank.value} value={bank.value}>{bank.label}</option>
                                  )
                                })}
                              </select>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Account Number to recieve payments <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="dollar-sign"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="accountNumber"
                                id="accountNumber"
                                type="number"
                                className="form-control ps-5"
                                placeholder="Account Number :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Preview an example storefront <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="globe"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="website"
                                id="url"
                                type="text"
                                className="form-control ps-5"
                                defaultValue={"https://myswhag.com/jaydensgamingempire"}
                                disabled
                              />
                              <a target="_blank" href={"https://myswhag.com/jaydensgamingempire"} rel="noreferrer">Preview</a>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} className="text-center">
                            <div className="d-grid">
                            <Button className="submitBnt btn btn-primary" onClick={createStore}>
                              Create store
                            </Button>
                            </div>
                          </Col>
                          {/* <Pricing changePlan={changePlan} /> */}
                        </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

        </section>
      </React.Fragment>
      </ConfirmProvider>
    );
  };
};

export default Store;
