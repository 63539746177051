import fire from '../Fire.js';

export const fetchBrands = async (productsPerLoad) => {
  const brands = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .where("verified", "==", true)
  .limit(productsPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(queryDocumentSnapshot => {
    var documentId = queryDocumentSnapshot.id;
    brands.push(Object.assign(queryDocumentSnapshot.data(), { useruid: documentId }));
  });
  
  return {brands, lastVisible};
};

export const fetchMoreBrands = async (startAfterBrands, productsPerLoad) => {
  const brands = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .where("verified", "==", true)
  .startAfter(startAfterBrands)
  .limit(productsPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(queryDocumentSnapshot => {
    var documentId = queryDocumentSnapshot.id;
    brands.push(Object.assign(queryDocumentSnapshot.data(), { useruid: documentId }));
  });
  
  return {brands, lastVisible};
};

export const fetchProducts = async (productsPerLoad) => {
  const products = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("products")
  .where("published", "==", true)
  .limit(productsPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(queryDocumentSnapshot => {
    var documentId = queryDocumentSnapshot.id;
    products.push(Object.assign(queryDocumentSnapshot.data(), { productId: documentId }));
  });
  
  return {products, lastVisible};
};

export const fetchMoreProducts = async (startAfter, productsPerLoad) => {
  const products = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("products")
  .where("published", "==", true)
  .startAfter(startAfter)
  .limit(productsPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(queryDocumentSnapshot => {
    var documentId = queryDocumentSnapshot.id;
    products.push(Object.assign(queryDocumentSnapshot.data(), { productId: documentId }));
  });
  
  return {products, lastVisible};
};

export const fetchNewProducts = async (productsPerLoad) => {
  const products = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("products")
  .orderBy('dateCreated', 'desc')
  .limit(productsPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(queryDocumentSnapshot => {
    var documentId = queryDocumentSnapshot.id;
    products.push(Object.assign(queryDocumentSnapshot.data(), { productId: documentId }));
  });
  
  return {products, lastVisible};
};

export const fetchMoreNewProducts = async (startAfterNew, productsPerLoad) => {
  const products = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("products")
  .orderBy('dateCreated', 'desc')
  .startAfter(startAfterNew)
  .limit(productsPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(queryDocumentSnapshot => {
    var documentId = queryDocumentSnapshot.id;
    products.push(Object.assign(queryDocumentSnapshot.data(), { productId: documentId }));
  });
  
  return {products, lastVisible};
};

export const fetchBestProducts = async (productsPerLoad) => {
  const products = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("products")
  .orderBy('sales', 'desc')
  .limit(productsPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(queryDocumentSnapshot => {
    var documentId = queryDocumentSnapshot.id;
    products.push(Object.assign(queryDocumentSnapshot.data(), { productId: documentId }));
  });
  
  return {products, lastVisible};
};

export const fetchMoreBestProducts = async (startAfterBest, productsPerLoad) => {
  const products = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("products")
  .orderBy('sales', 'desc')
  .startAfter(startAfterBest)
  .limit(productsPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(queryDocumentSnapshot => {
    var documentId = queryDocumentSnapshot.id;
    products.push(Object.assign(queryDocumentSnapshot.data(), { productId: documentId }));
  });
  
  return {products, lastVisible};
};

export const fetchRelatedProducts = async ({userUid, storeId}) => {
  const products = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("products")
  .where("published", "==", true)
  .limit(20)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(queryDocumentSnapshot => {
    var documentId = queryDocumentSnapshot.id;
    products.push(Object.assign(queryDocumentSnapshot.data(), { productId: documentId }));
  });
  
  return {products, lastVisible};
};

export const fetchBrandProducts = async ({userUid, storeId}) => {
  const products = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("products")
  .where("published", "==", true)
  .limit(10)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(queryDocumentSnapshot => {
    var documentId = queryDocumentSnapshot.id;
    products.push(Object.assign(queryDocumentSnapshot.data(), { productId: documentId }));
  });
  
  return {products, lastVisible};
};

export const fetchMoreBrandProducts = async ({startAfter, userUid, storeId}) => {
  const products = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("products")
  .where("published", "==", true)
  .startAfter(startAfter)
  .limit(10)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(queryDocumentSnapshot => {
    var documentId = queryDocumentSnapshot.id;
    products.push(Object.assign(queryDocumentSnapshot.data(), { productId: documentId }));
  });
  
  return {products, lastVisible};
};

export const fetchMyProducts = async (productsPerLoad, storeId, userUid) => {
  const products = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("products")
  .limit(productsPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(queryDocumentSnapshot => {
    var documentId = queryDocumentSnapshot.id;
    products.push(Object.assign(queryDocumentSnapshot.data(), { productId: documentId }));
  });
  
  return {products, lastVisible};
};

export const fetchAllOrders = async ({storeId, userUid, startAt, endAt}) => {
  const orders = new Array();
  
  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("orders")
  .where('timestamp', '>', startAt)
  .where('timestamp', '<', endAt)
  .orderBy('timestamp', 'desc')
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];

  querySnapshot.forEach(async (order) => {
    const products = [];
    await fire
    .firestore()
    .collection("users")
    .doc(userUid)
    .collection("stores")
    .doc(storeId)
    .collection("orders")
    .doc(order.id)
    .collection("products")
    .get()
    .then(productsOrdered => {
      productsOrdered.forEach(productOrdered => {
        products.push(productOrdered.data());
      });
    });
    orders.push(Object.assign(order.data(), { products: products, orderId: order.id}));
  });

  return {orders, lastVisible};
};

export const fetchOrders = async ({ordersPerLoad, storeId, userUid, startAt, endAt}) => {
  const orders = new Array();
  
  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("orders")
  .where('timestamp', '>', startAt)
  .where('timestamp', '<', endAt)
  .orderBy('timestamp', 'desc')
  .limit(ordersPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach((order) => {
    const products = [];
    fire
    .firestore()
    .collection("users")
    .doc(userUid)
    .collection("stores")
    .doc(storeId)
    .collection("orders")
    .doc(order.id)
    .collection("products")
    .get()
    .then(productsOrdered => {
      productsOrdered.forEach(productOrdered => {
        products.push(productOrdered.data());
      });
    });
    orders.push(Object.assign(order.data(), { products: products, orderId: order.id}));
  });
  return {orders, lastVisible};
};

export const fetchMoreOrders = async ({startAfter, ordersPerLoad, storeId, userUid, startAt, endAt}) => {
  const orders = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("orders")
  .where('timestamp', '>', startAt)
  .where('timestamp', '<', endAt)
  .orderBy('timestamp', 'desc')
  .startAfter(startAfter)
  .limit(ordersPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(order => {
    const products = [];
    fire
    .firestore()
    .collection("users")
    .doc(userUid)
    .collection("stores")
    .doc(storeId)
    .collection("orders")
    .doc(order.id)
    .collection("products")
    .get()
    .then(productsOrdered => {
      productsOrdered.forEach(productOrdered => {
        products.push(productOrdered.data());
      });
    });
    orders.push(Object.assign(order.data(), { products: products, orderId: order.id}));
  });
  
  return {orders, lastVisible};
};

export const fetchCustomers = async ({ordersPerLoad, storeId, userUid}) => {
  const customers = new Array();
  
  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("customers")
  .orderBy('customerFirstName', 'asc')
  .limit(ordersPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(customer => {
    const allOrders = [];
    const allProducts = [];
    fire
    .firestore()
    .collection("users")
    .doc(userUid)
    .collection("stores")
    .doc(storeId)
    .collection("customers")
    .doc(customer.id)
    .collection("orders")
    .get()
    .then(orders => {
      orders.forEach(order => {
        allOrders.push(order.data());
      });
    });
    fire
    .firestore()
    .collection("users")
    .doc(userUid)
    .collection("stores")
    .doc(storeId)
    .collection("customers")
    .doc(customer.id)
    .collection("shoppingInterests")
    .get()
    .then(categories => {
      categories.forEach(category => {
        allProducts.push(Object.assign(category.data(), {id: category.id}));
      });
    });
    customers.push(Object.assign(customer.data(), { orders: allOrders, customerId: customer.id, allProducts: allProducts}));
  });
  return {customers, lastVisible};
};

export const fetchMoreCustomers = async ({startAfter, ordersPerLoad, storeId, userUid}) => {
  const customers = new Array();
  
  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("customers")
  .orderBy('customerFirstName', 'asc')
  .startAfter(startAfter)
  .limit(ordersPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(customer => {
    const allOrders = [];
    const allProducts = [];
    fire
    .firestore()
    .collection("users")
    .doc(userUid)
    .collection("stores")
    .doc(storeId)
    .collection("customers")
    .doc(customer.id)
    .collection("orders")
    .get()
    .then(orders => {
      orders.forEach(order => {
        allOrders.push(order.data());
      });
    });
    fire
    .firestore()
    .collection("users")
    .doc(userUid)
    .collection("stores")
    .doc(storeId)
    .collection("customers")
    .doc(customer.id)
    .collection("shoppingInterests")
    .get()
    .then(categories => {
      categories.forEach(category => {
        allProducts.push(Object.assign(category.data(), {id: category.id}));
      });
    });
    customers.push(Object.assign(customer.data(), { orders: allOrders, customerId: customer.id, allProducts: allProducts}));
  });
  return {customers, lastVisible};
};

export const fetchCustomerOrders = async ({customerEmail, ordersPerLoad, storeId, userUid, startAt, endAt}) => {
  const orders = new Array();
  
  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("orders")
  //.where('customerEmail', '==', customerEmail)
  .where('timestamp', '>', startAt)
  .where('timestamp', '<', endAt)
  .orderBy('timestamp', 'desc')
  .limit(ordersPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(order => {
    const products = [];
    if (customerEmail === order.data().customerEmail) {
      fire
      .firestore()
      .collection("users")
      .doc(userUid)
      .collection("stores")
      .doc(storeId)
      .collection("orders")
      .doc(order.id)
      .collection("products")
      .get()
      .then(productsOrdered => {
        productsOrdered.forEach(productOrdered => {
          products.push(productOrdered.data());
        });
      });
      orders.push(Object.assign(order.data(), { products: products, orderId: order.id}));
    };
  });
  return {orders, lastVisible};
};

export const fetchMoreCustomersOrders = async ({customerEmail, startAfter, ordersPerLoad, storeId, userUid, startAt, endAt}) => {
  const orders = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("orders")
  //.where('customerEmail', '==', customerEmail)
  .where('timestamp', '>', startAt)
  .where('timestamp', '<', endAt)
  .orderBy('timestamp', 'desc')
  .startAfter(startAfter)
  .limit(ordersPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(order => {
    const products = [];
    if (customerEmail === order.data().customerEmail) {
      fire
      .firestore()
      .collection("users")
      .doc(userUid)
      .collection("stores")
      .doc(storeId)
      .collection("orders")
      .doc(order.id)
      .collection("products")
      .get()
      .then(productsOrdered => {
        productsOrdered.forEach(productOrdered => {
          products.push(productOrdered.data());
        });
      });
      orders.push(Object.assign(order.data(), { products: products, orderId: order.id}));
    };
  });
  
  return {orders, lastVisible};
};

export const fetchRecommendedProducts = async ({productsPerLoad, userUid, storeId, customerEmail}) => {
  const products = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("customers")
  .doc(customerEmail)
  .collection("recommendedProducts")
  .orderBy('productMatch', 'desc')
  .limit(productsPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(queryDocumentSnapshot => {
    var documentId = queryDocumentSnapshot.id;
    products.push({productId: documentId, productMatch: queryDocumentSnapshot.data().productMatch});
  });
  
  return {products, lastVisible};
};

export const fetchMoreRecommendedProducts = async ({startAfter, productsPerLoad, userUid, storeId, customerEmail}) => {
  const products = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("customers")
  .doc(customerEmail)
  .collection("recommendedProducts")
  .orderBy('productMatch', 'desc')
  .startAfter(startAfter)
  .limit(productsPerLoad)
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(queryDocumentSnapshot => {
    var documentId = queryDocumentSnapshot.id;
    products.push({productId: documentId, productMatch: queryDocumentSnapshot.data().productMatch});
  });
  
  return {products, lastVisible};
};

export const fetchAnalyticEvents = async ({storeId, userUid, dataType, startAt, endAt}) => {
  const events = new Array();

  
  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("analytics")
  .doc(dataType)
  .collection("events")
  .where('timestamp', '>', startAt)
  .where('timestamp', '<', endAt)
  .orderBy('timestamp')
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(event => {
    events.push(event.data());
  });
  return {events, lastVisible};
};

export const fetchAnalyticEventsDropshipping = async ({storeId, userUid, dataType, startAt, endAt}) => {
  const events = new Array();

  
  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("analytics")
  .doc(dataType)
  .collection("events")
  .where('timestamp', '>', startAt)
  .where('timestamp', '<', endAt)
  .orderBy('timestamp')
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(event => {
    if (event.data().dropshipping === true) {
      events.push(event.data());
    };
  });
  return {events, lastVisible};
};

export const fetchAnalyticEventsDropshippingPartner = async ({storeId, userUid, dataType, startAt, endAt, partner, verified}) => {
  const events = new Array();
  
  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("analytics")
  .doc(dataType)
  .collection("events")
  .where('timestamp', '>', startAt)
  .where('timestamp', '<', endAt)
  .orderBy('timestamp')
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  if (verified === true) {
    querySnapshot.forEach(event => {
      if (event.data().dropshipping === true && event.data().storeName === partner) {
        events.push(event.data());
      };
    });
  } else {
    querySnapshot.forEach(event => {
      if (event.data().dropshipping === true && event.data().merchantStoreName === partner) {
        events.push(event.data());
      };
    });
  }
  return {events, lastVisible};
};

export const fetchReferrals = async ({storeId, userUid, startAt, endAt}) => {
  const referrals = new Array();

  const querySnapshot = await fire
  .firestore()
  .collection("users")
  .doc(userUid)
  .collection("stores")
  .doc(storeId)
  .collection("referrals")
  .where('dateCreated', '>', startAt)
  .where('dateCreated', '<', endAt)
  .orderBy('dateCreated')
  .get();
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
  querySnapshot.forEach(event => {
    referrals.push(event.data());
  });
  return {referrals, lastVisible};
};