import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { green } from '@material-ui/core/colors';
import FeatherIcon from "feather-icons-react";
import InputLabel from '@material-ui/core/InputLabel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ButtonGroup from '@mui/material/ButtonGroup';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Comingsoon from './Comingsoon';

import { ConfirmProvider } from 'material-ui-confirm';
import { useConfirm } from 'material-ui-confirm';
import fire from "../Fire.js";
import firebase from 'firebase';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function useStore() {
  const [store, setStore] = React.useState({});

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        setStore(Object.assign(doc.data(), {storeId: doc.id}));
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

function useProducts() {
  const [products, setProducts] = React.useState([]);
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const id = [];
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(queryDocumentSnapshot => {
        var documentId = queryDocumentSnapshot.id;
        var storeInfo = queryDocumentSnapshot.data();
        id.push(documentId);
        id.forEach((item) => {
          fire
          .firestore()
          .collection("users")
          .doc(user.uid)
          .collection("stores")
          .doc(item)
          .collection("products")
          .onSnapshot(queryDocumentSnapshot => {
            setProducts([]);
            queryDocumentSnapshot.forEach(eachDocument => {
              const doc = eachDocument.data();
              setProducts(currentProducts => [...currentProducts, Object.assign(doc, {storeName: storeInfo.storeName, storeId: documentId, docId: eachDocument.id,})]);
            });
          });
        });
      });
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return products;
};

function useProfile() {
  const [profile, setProfile] = React.useState({});

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .onSnapshot(document => {
      setProfile(document.data());
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return profile;
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'picUrl',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Product',
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: false,
    label: 'Price (NGN)',
  },
  {
    id: 'maxPrice',
    numeric: true,
    disablePadding: false,
    label: 'Max selling price (NGN)',
  },
  {
    id: 'quantity',
    numeric: true,
    disablePadding: false,
    label: 'Inventory',
  },
  {
    id: 'collection',
    numeric: false,
    disablePadding: false,
    label: 'Collection',
  },
  {
    id: 'publsihed',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const MyProducts = (props) => {
  const user = fire.auth().currentUser;
  const store = useStore();
  const profile = useProfile();
  const confirm = useConfirm();
  let history = useHistory();
  const products = useProducts();
  const categories = ["Women's Fashion", "Men's Fashion", "Gaming", "Beauty", "Accessories", "Gym and Fitness", "Electronics", "Others"];
  const [isModalOpen, setOpenModal] = React.useState(false);
  const [product, setProduct] = React.useState({});
  const [isOpenNewProduct, setOpenNewProduct] = React.useState(false);
  const [isMultipleSizes, setIsMultipleSizes] = React.useState(false);
  const [isOpenNewCollection, setOpenNewCollection] = React.useState(false);
  const [isOpenImport, setOpenImport] = React.useState(false);
  const [isOnSale, setOnSale] = React.useState(false);
  const [collection, setCollection] = React.useState("");
  const [productId, setProductId] = React.useState();

  const [searchField, setSearchField] = React.useState("");
  const [myFilter, setMyFilter] = React.useState("All");

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('productName');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [csvFile, setCsvFile] = React.useState();
  const [isSnackbarImportOpen, setOpenSnackbarImport] = React.useState(false);
  const [openLoading, setOpenLoading] = React.useState(false);

  let addMultipleSizes;
  let editProduct;

  const search = (products) => {
    const isTag = (tags) => {
      //return -1
      if(tags){
        return tags.findIndex(tag => {
          if (tag.includes(searchField)) {
            return true;
          }
        })
      } else{
        return -1;
      }
    };
    const x = products.filter(
      (product) => 
      (
        (product.name.toLowerCase().indexOf(searchField) > -1 ||
        isTag(product.productTags) > -1) 
        &&
        (product.collection === myFilter || myFilter === "All") &&
        (product.dropshipping !== true)
      )
    );
    return x;
  };

  const handleAlignment = (event, newAlignment) => {
    setMyFilter(newAlignment);
  };

  const openNewProduct = (e) => {
    setOpenNewProduct(true);
  };

  const publishProducts = (e) => {
    confirm({ description: 'Are you sure you want to publish these products to SWHAG?', confirmationText: 'Yes' })
      .then(() => { 
        if(profile.verified === true) {
          setOpenLoading(true);
          selected.forEach((product, index) => {
            fire
            .firestore()
            .collection("users")
            .doc(user.uid)
            .collection("stores")
            .doc(store.storeId)
            .collection("products")
            .doc(product.docId)
            .update({
              status: "Pending verification",
              storeName: store.storeName,
            });

            fire
            .firestore()
            .collection("products")
            .doc(product.productId)
            .set(product)
            .then(()=> {
              if ((selected.length-1) === index){
                setOpenLoading(false);
              }
            });

            fire
            .firestore()
            .collection("products")
            .doc(product.docId)
            .update({
              status: "Pending verification",
              storeName: store.storeName,
            });
            
          });
        };
        setSelected([]);
      })
      .catch(() => { /* ... */ });
  };

  const unPublishProducts = (e) => {
    confirm({ description: 'Are you sure you want to move these products to your drafts?', confirmationText: 'Yes' })
      .then(() => { 
        selected.forEach(product => {
          fire
          .firestore()
          .collection("users")
          .doc(user.uid)
          .collection("stores")
          .doc(store.storeId)
          .collection("products")
          .doc(product.docId)
          .update({
            published: false,
            status: "unpublished",
            storeName: store.storeName,
          });

          // fire
          // .firestore()
          // .collection("products")
          // .doc(product.productId)
          // .set(product);

          fire
          .firestore()
          .collection("products")
          .doc(product.docId)
          .update({
            published: false,
            status: "unpublished",
            storeName: store.storeName,
          });
          
        });
        setSelected([]);
      })
      .catch(() => { /* ... */ });
  };

  const createCollection = (e) => {
    e.preventDefault();
    const collectionName = document.getElementById("collectionName").value;
    setOpenNewCollection(false);
    fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId)
    .update({
      collections: firebase.firestore.FieldValue.arrayUnion({name: collectionName, picUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fcollection%2Ffea3.jpg?alt=media&token=1e0dec2b-2a37-4924-a67d-31851570f0dc"}),
    });
    fire.firestore().collection("stores").doc(store.storeId)
    .update({
      collections: firebase.firestore.FieldValue.arrayUnion({name: collectionName, picUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fcollection%2Ffea3.jpg?alt=media&token=1e0dec2b-2a37-4924-a67d-31851570f0dc"}),
    });
  };


  const openModal = (e) => {
    setOpenModal(true);
    setProduct(e);
  };
  const closeModal = () => {
    setOpenModal(false);
    setOpenNewProduct(false);
    setOnSale(false);
    setOpenNewCollection(false);
    setOpenImport(false);
  };

  const changeProductVariants = (e) => {
    const multipleSizes = Boolean(document.getElementById("oneSize").value);
    setIsMultipleSizes(multipleSizes);
  };
  const changeCollection = (e) => {
    const collection = document.getElementById("collection").value;
    setCollection(collection);
  };
  if(isMultipleSizes === true){
    addMultipleSizes = (
      <Col md={6}>
        <div className="mb-3">
          <Label className="form-label">
            Enter the different product sizes you have separate them with a comma <span className="text-danger">*</span>
          </Label>
          <Input
            name="name"
            id="variants"
            type="text"
            className="form-control ps-5"
            placeholder="e.g 'XS,S,M,L,XL,2XL'"
          />
        </div>
      </Col>
    );
  } else {
    addMultipleSizes = (
      <div></div>
    );
  };

  const newProductId = (e) => {
    setProductId(e.productId);
  };

  const selectPic = (e) => {
    const image = e.target.files[0];
    const productDocument = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(productId);
    const storageRef = fire.storage().ref('users/'+user.uid+'/stores/'+store.storeId+'/products/'+productId);
    const fileRef = storageRef.child('productImage');
    fileRef.put(image).then((e) => {
      fileRef.getDownloadURL().then((url) => {
        productDocument
        .update({
          picUrl: url,
        });
        productDocument
        .get()
        .then(doc => {
          if( (profile.verified === true) && (doc.data().published === true)) {
            fire.firestore().collection("products").doc(productId).update({
              picUrl: url,
            });
          };
        });
      });
    });
    setOpenModal(false);
  };

  const updateProduct = (e) => {
    const name = document.getElementById("name").value;
    const cost = document.getElementById("cost").value;
    const maxPrice = document.getElementById("maxPrice").value;
    //const deliveryFee = document.getElementById("deliveryFee").value;
    const quantity = document.getElementById("quantity").value;
    const description = document.getElementById("description").value;
    const category = document.getElementById("category").value;
    let productTags = document.getElementById("productTags").value;
    let productTagsArray = [];
    if(productTags){
      productTagsArray = productTags.split(","); 
    }

    if(e.dropshipping === true) {
      fire
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("stores")
      .doc(product.storeId)
      .collection("products")
      .doc(product.docId)
      .update({
        price: Number(cost),
        description: description,
      });
    } else {
      fire
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("stores")
      .doc(store.storeId)
      .collection("products")
      .doc(product.docId)
      .update({
        name: name,
        price: Number(cost), 
        cost: Number(cost),
        quantity: Number(quantity),
        description: description,
        category: category,
        productTags: productTagsArray,
        storeName: store.storeName,
      });
      if(Number(maxPrice) > Number(cost)){
        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("stores")
        .doc(store.storeId)
        .collection("products")
        .doc(product.docId)
        .update({
          maxPrice: Number(maxPrice),
        });
      };
      if (e.multipleSizes === true) {
        const variants = document.getElementById("variants").value;
        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("stores")
        .doc(store.storeId)
        .collection("products")
        .doc(product.docId)
        .update({
          variants: variants.split(","),
        });
      };
      if(e.published === true && profile.verified === true) {
        fire
        .firestore()
        .collection("products")
        .doc(product.productId)
        .update({
          name: name,
          price: Number(cost),
          cost: Number(cost),
          quantity: Number(quantity),
          description: description,
          useruid: user.uid,
          storeId: store.storeId,
          category: category,
          productTags: productTagsArray,
          storeName: store.storeName,
        });
        if(Number(maxPrice) > Number(cost)){
          fire
          .firestore()
          .collection("products")
          .doc(product.docId)
          .update({
            maxPrice: Number(maxPrice),
          });
        };
        if (e.multipleSizes === true) {
          const variants = document.getElementById("variants").value;
          fire
          .firestore()
          .collection("products")
          .doc(product.docId)
          .update({
            variants: variants.split(","),
          });
        };
        if (store.collections) {
          const collection = document.getElementById("collection").value;
          fire
          .firestore()
          .collection("products")
          .doc(product.docId)
          .update({
            brandCollection: collection,
          });
        };
      };
      if (store.collections) {
        const collection = document.getElementById("collection").value;
        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("stores")
        .doc(store.storeId)
        .collection("products")
        .doc(product.docId)
        .update({
          brandCollection: collection,
        });
      };
    };
    if (store.collections) {
      const collection = document.getElementById("collection").value;
      fire
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("stores")
      .doc(store.storeId)
      .collection("products")
      .doc(product.docId)
      .update({
        collection: collection,
      });
    };
    setOpenModal(false);
  };

  
  editProduct=(
    <div>
      <ModalHeader toggle={closeModal}>{product.name}</ModalHeader>
      <ModalBody className="p-4">
        <Container fluid className="px-0">
          <Row>
            <h4 className="card-title">Edit your Product!</h4>
            <Col lg={5}>
              <div className="tiny-single-item">
                <div className="tiny-slide"><img src={product.picUrl} className="img-fluid rounded" alt="" /></div>
              </div>
              <InputLabel style={{display: 'flex', justifyContent: 'center',alignItems:'center'}}>
                <Input type="file" onChange={selectPic} onClick={() => newProductId(product)} style={{display: 'none'}} />
                <Typography color="primary">Change product image</Typography>
              </InputLabel>
            </Col>
            {(product.status === "Warning") && (
            <Col lg={7} style={{display: 'flex', alignItems:'center'}}>
              <Row>
              <Col xs={12} style={{alignItems:'center', marginBottom: 10, fontWeight: "bold"}}>
              <Typography >Why was your product issued a warning?</Typography>
              </Col>
              <Col xs={12} style={{alignItems:'center', marginBottom: 10}}>
              <Typography >{product.rejectionTitle}</Typography>
              </Col>
              <Col xs={12} style={{alignItems:'center', marginBottom: 10}}>
              <Typography >{product.rejectionDescription}</Typography>
              </Col>
              <Col xs={12} style={{alignItems:'center'}}>
              <Typography >Please address our concerns highlighted above or we may remove your product from our platform</Typography>
              </Col>
              </Row>
            </Col>
            )}
            {(product.status === "Rejected") && (
            <Col lg={7} style={{display: 'flex', alignItems:'center'}}>
              <Row>
              <Col xs={12} style={{alignItems:'center', marginBottom: 10, fontWeight: "bold"}}>
              <Typography >Why was your product rejected?</Typography>
              </Col>
              <Col xs={12} style={{alignItems:'center', marginBottom: 10}}>
              <Typography >{product.rejectionTitle}</Typography>
              </Col>
              <Col xs={12} style={{alignItems:'center', marginBottom: 10}}>
              <Typography >{product.rejectionDescription}</Typography>
              </Col>
              <Col xs={12} style={{alignItems:'center'}}>
              <Typography >Please feel free to re-publish after addressing our concerns</Typography>
              </Col>
              </Row>
            </Col>
            )}
            <div className="custom-form mt-3">
              <div id="message"></div>
              <Form
                name="contact-form"
                id="contact-form"
              >
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Your Product Name <span className="text-danger">*</span>
                      </Label>
                      <div className="form-icon position-relative">
                        <i>
                          <FeatherIcon
                            icon="package"
                            className="fea icon-sm icons"
                          />
                        </i>
                      </div>
                      <Input
                        name="name"
                        id="name"
                        type="text"
                        className="form-control ps-5"
                        placeholder="Product Name:"
                        defaultValue={product.name}
                        required
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Product price <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="price"
                        id="cost"
                        type="number"
                        className="form-control ps-5"
                        placeholder="5000"
                        defaultValue={product.cost}
                        required
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Max selling price <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="price"
                        id="maxPrice"
                        type="number"
                        className="form-control ps-5"
                        placeholder="5000"
                        defaultValue={product.maxPrice}
                        required
                      />
                    </div>
                  </Col>
                  {/* <Col md={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Delivery Fee <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="price"
                        id="deliveryFee"
                        type="number"
                        className="form-control ps-5"
                        placeholder="000"
                        defaultValue={product.deliveryFee}
                        required
                      />
                    </div>
                  </Col> */}
                  <Col md={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Quantity <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="quantity"
                        id="quantity"
                        type="number"
                        className="form-control ps-5"
                        placeholder="100"
                        defaultValue={product.quantity}
                        required
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Enter the different product variants you have, separate them with a comma <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="name"
                        id="productTags"
                        type="text"
                        className="form-control ps-5"
                        placeholder="e.g fitness,gym,sport,exercise"
                        defaultValue={product.productTags}
                      />
                    </div>
                  </Col>
                  {product.multipleSizes && (
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Enter the different product sizes you have separate them with a comma <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="name"
                          id="variants"
                          type="text"
                          className="form-control ps-5"
                          defaultValue={product.variants}
                        />
                      </div>
                    </Col>
                  )}
                  {(store.collections) && (
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Collection <span className="text-danger">*</span>
                        </Label>
                        <select className="btn btn-icon form-control ps-5 quantity" id="collection" defaultValue={product.collection} style={{width: "100%"}}>
                          {store.collections.map((col, index) => {
                            return (
                              <option key={index} value={col.name}>{col.name}</option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                  )}
                  <Col md={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Category <span className="text-danger">*</span>
                      </Label>
                      <select className="btn btn-icon form-control ps-5 quantity" id="category" defaultValue={product.category} style={{width: "100%"}}>
                        {categories.map((col, index) => {
                          return (
                            <option key={index} value={col}>{col}</option>
                          );
                        })}
                      </select>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Description{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <div className="form-icon position-relative">
                        <i>
                          <FeatherIcon
                            icon="message-square"
                            className="fea icon-sm icons"
                          />
                        </i>
                      </div>
                      <textarea
                        name="description"
                        id="description"
                        type="text"
                        className="form-control ps-5"
                        placeholder="Describe the product you are selling:"
                        rows={5}
                        defaultValue={product.description}
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="text-center">
                    <div className="d-grid">
                      <input
                        id="submit"
                        name="send"
                        className="submitBnt btn btn-primary"
                        value="Update product"
                        onClick={() =>updateProduct(product)}
                      />
                    </div>
                    <div id="simple-msg"></div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Row>
        </Container>
      </ModalBody>
    </div>
  );

  const createProduct = (e) => {
    e.preventDefault();
    const name = document.getElementById("name").value;
    const price = document.getElementById("price").value;
    const maxPrice = document.getElementById("maxPrice").value;
    //const deliveryFee = document.getElementById("deliveryFee").value;
    const quantity = document.getElementById("quantity").value;
    const multipleSizes = Boolean(document.getElementById("oneSize").value);
    const description = document.getElementById("description").value;
    const category = document.getElementById("category").value;
    let productTags = document.getElementById("productTags").value;
    let productTagsArray = [];
    if(productTags){
      productTagsArray = productTags.split(","); 
    }

    const productRef = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc();
    productRef.set({useruid: user.uid, email: user.email, merchantFirstName: profile.firstName, merchantLastName: profile.lastName, subaccount: store.subaccount, storeId: store.storeId, storeName: store.storeName, ownedByStore: true, productId: productRef.id, name: name, price: Number(price), maxPrice: Number(maxPrice), cost: Number(price), quantity: Number(quantity), multipleSizes: false, description: description, deliveryFee: Number(0), category: category, productTags: productTagsArray, published: false, isMerch: false, dateCreated: firebase.firestore.FieldValue.serverTimestamp(), sales: 0, picUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/products%2F--default--%2Fdefault-product.jpg?alt=media&token=e5702835-a4b6-4131-a789-5d0207286b42"});
    if(multipleSizes === true) {
      const variants = document.getElementById("variants").value;
      fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(productRef.id).update({
        variants: variants.split(","),
        multipleSizes: true,
      });
    };

    if (store.collections){
      const collection = document.getElementById("collection").value;
      fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(productRef.id).update({
        collection: collection,
        brandCollection: collection,
      });
    };

  };

  const removeProducts = (e) => {
    confirm({ description: 'Are you sure you want to remove these products from your store?', confirmationText: 'Yes' })
      .then(() => { 
        setOpenLoading(true);
        selected.forEach(product => {

          if (product.dropshipping === true){
            fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(product.docId).delete();
          } else {
            fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(product.docId).delete();
            if (product.published === true && profile.verified === true) {
              const deleteMerchantProduct = fire.functions().httpsCallable('deleteMerchantProduct');
              deleteMerchantProduct({productId: product.productId}).then(result => {
                console.log(result.data);
              });
            };
          };
        });
      })
      .then(()=> {
        setOpenLoading(false);
      })
      .catch(() => { /* ... */ });
      setSelected([]);
  };

  const checkQuantity = () => {
    products.forEach(product => {
      if (product.dropshipping === true){
        fire.firestore().collection("products").doc(product.productId).get().then(doc => {
          const originalProduct = doc.data();
          const totalCosts = Number(originalProduct.cost) + Number(originalProduct.deliveryFee);
          if (originalProduct.published === true){
            fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(product.docId).update({
              quantity: Number(originalProduct.quantity),
              deliveryFee: Number(originalProduct.deliveryFee),
              cost: Number(originalProduct.cost),
            });
            if (product.price <= totalCosts){
              fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(product.docId).update({
                price: totalCosts*1.2,
              });
            };
            if (originalProduct.multipleSizes === true){
              fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(product.docId).update({
                variants: originalProduct.variants,
              });
            };
          } else {
            fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(product.docId).update({
              quantity: 0,
            });
          };
        });
      };
    });
  };

  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Products
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button onClick={publishProducts} style={{color:'green'}}>Publish</Button>
            <Button onClick={unPublishProducts} style={{color:'orange'}}>Unpublish</Button>
            <Button onClick={removeProducts} title="Delete"><DeleteIcon style={{color:'red'}} /></Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button onClick={() => setOpenImport(true)} variant="outlined">
              Import products
            </Button>
            <Button onClick={() => setOpenNewCollection(true)} variant="outlined">
              New Collection
            </Button>
          </ButtonGroup>
        )}
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = products.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - products.length) : 0;

  const processCSV = (str, delim=',') => {
    const headers = str.slice(0, str.indexOf('\n')).replace(/\r?\n|\r/g,"").split(delim);
    const rows = str.slice(str.indexOf('\n')+1).split('\n');

    const newArray = rows.map ( row => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {});
      return eachObject;
    })


    newArray.forEach((array, index) => {
      const name = array.name;
      const price = Number(array.price);
      const maxPrice = Number(array.maxPrice);
      //const deliveryFee = store.deliveryFee;
      const quantity = Number(array.quantity);
      let multipleSizes = false;
      const description = array.description;
      const variants = array.variants;
      const productTags = array.productTags;
      let category = "Others";
      categories.forEach((item)=> {
        if (item.toLowerCase().replace(" ", "") === array.category.toLowerCase().replace(" ", "")){
          category = item;
        }
      });
      if (array.multipleVariants.toLowerCase().replace(" ", "") === "yes"){
        multipleSizes = true;
      }
      

      const productRef = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc();
      productRef.set({useruid: user.uid, email: user.email, merchantFirstName: profile.firstName, merchantLastName: profile.lastName, subaccount: store.subaccount, storeId: store.storeId, storeName: store.storeName, ownedByStore: true, productId: productRef.id, name: name, price: Number(price), maxPrice: Number(maxPrice), cost: Number(price), quantity: Number(quantity), multipleSizes: false, description: description, deliveryFee: Number(0), category: category, productTags: productTags.split(";"), published: false, isMerch: false, dateCreated: firebase.firestore.FieldValue.serverTimestamp(), sales: 0, picUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/products%2F--default--%2Fdefault-product.jpg?alt=media&token=e5702835-a4b6-4131-a789-5d0207286b42"})
      .then(()=> {
        if ((newArray.length-1) === index){
          setOpenLoading(false);
          setOpenSnackbarImport(true);
        }
      });
      if(multipleSizes === true) {
        fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(productRef.id).update({
          variants: variants.split(";"),
          multipleSizes: true,
        });
      };

      if (store.collections){
        let collection = store.collections[0].name;
        store.collections.forEach((item)=> {
          if (item.name.toLowerCase().replace(" ", "") === array.collection.toLowerCase().replace(" ", "")){
            collection = item.name;
          }
        });
        fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(productRef.id).update({
          collection: collection,
          brandCollection: collection,
        });
      };

    });
  };

  const importProducts = (e) => {
    e.preventDefault();
    const file = csvFile;
    const reader = new FileReader();


    reader.onload = function(a) {
      const text = a.target.result;
      processCSV(text);
    }

    reader.readAsText(file);

    setOpenImport(false);
    setOpenLoading(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbarImport(false);
  };

  if(profile.verified !== true){
    return(<Comingsoon/>);
  } else {
  return (
    <ConfirmProvider>
    <React.Fragment>
    <section className="section" style={{width: "100%", flex: 1, height: "100%", minHeight: "100vh", backgroundColor: '#f2f8fb'}}>
      {/* breadcrumb */}
      <Container>
          <Paper sx={{ width: '100%', mb: 2 }}>
            {(store.collections) && (
              <ToggleButtonGroup
                value={myFilter}
                color="primary"
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
              >
                <ToggleButton value="All" aria-label="All">
                  <Typography>All</Typography>
                </ToggleButton>
                {store.collections.map((col) => {
                  return(
                    <ToggleButton value={col.name} aria-label={col.name}>
                      {col.name}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            )}
            <Form
              role="search"
              method="get"
              id="searchform"
            >
              <div className="input-group mb-3 border rounded">
                <button type="submit" onClick={(e) => e.preventDefault()} className="input-group-text bg-white border-0" id="searchsubmit"><i className="uil uil-search"></i></button>
                <Input
                  type="text"
                  className="form-control border-0"
                  name="s"
                  id="search"
                  placeholder="Filter products"
                  onChange={(e) => setSearchField(e.target.value.toLowerCase())}
                />
                <Button onClick={openNewProduct} variant="contained" style={{ backgroundColor: green[500], color: "#ffffff"}}>
                  Add product
                </Button>
              </div>
            </Form>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={search(products).length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                    rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(search(products), getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      let status;
                      if (row.published === true) {
                        status = (<TableCell align="right"><div style={{backgroundColor: green[500], padding: 10, borderRadius: '50%', textAlign: 'center'}}>Published</div></TableCell>);
                      } else {
                        status = (<TableCell align="right"><div style={{backgroundColor: "orange", padding: 10, borderRadius: '50%', textAlign: 'center'}}><Typography>Drafts</Typography></div></TableCell>);
                      };

                      if (row.status === "Pending verification") {
                        status = (<TableCell align="right"><div style={{backgroundColor: "orange", padding: 10, borderRadius: '50%', textAlign: 'center'}}><Typography>In review</Typography></div></TableCell>);
                      } else if (row.status === "Warning") {
                        status = (<TableCell align="right"><div style={{backgroundColor: "orange", padding: 10, borderRadius: '50%', textAlign: 'center'}}><Typography>{row.status}</Typography></div></TableCell>);
                      } else if (row.status === "Rejected") {
                        status = (<TableCell align="right"><div style={{backgroundColor: "red", padding: 10, borderRadius: '50%', textAlign: 'center'}}><Typography>{row.status}</Typography></div></TableCell>);
                      };

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.productId}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              onClick={(event) => handleClick(event, row)}
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell onClick={() => openModal(row)}>
                            <img
                              src={row.picUrl}
                              style={{width: 100, height: 100, borderRadius: '30%'}}
                              alt="SWHAG"
                            />
                          </TableCell>
                          <TableCell
                            onClick={() => openModal(row)}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="right" onClick={() => openModal(row)}>NGN {row.price}</TableCell>
                          <TableCell align="right" onClick={() => openModal(row)}>NGN {row.maxPrice}</TableCell>
                          <TableCell align="right" onClick={() => openModal(row)}>{row.quantity}</TableCell>
                          <TableCell align="right" onClick={() => openModal(row)}>{row.collection}</TableCell>
                          {status}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={search(products).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

          <Modal isOpen={isModalOpen} toggle={closeModal} centered size="lg">
            {editProduct}
          </Modal>
          <Modal isOpen={isOpenNewProduct} toggle={closeModal} centered size="lg">
            <ModalHeader toggle={closeModal}>New Product</ModalHeader>

            <ModalBody className="p-4">
              <Container fluid className="px-0">
                <Row>
                  <h4 className="card-title">Create your Product!</h4>
                  <div className="custom-form mt-3">
                    <div id="message"></div>
                    <Form
                      method="post"
                      onSubmit={createProduct}
                      name="contact-form"
                      id="contact-form"
                    >
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Product Name <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="package"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              name="name"
                              id="name"
                              type="text"
                              className="form-control ps-5"
                              placeholder="Product Name:"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Product price <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="price"
                              id="price"
                              type="number"
                              className="form-control ps-5"
                              placeholder="5000"
                              defaultValue={5000}
                              required
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Maximum selling price <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="price"
                              id="maxPrice"
                              type="number"
                              className="form-control ps-5"
                              placeholder="5000"
                              defaultValue={8000}
                              required
                            />
                          </div>
                        </Col>
                        {/* <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Delivery Fee <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="price"
                              id="deliveryFee"
                              type="number"
                              className="form-control ps-5"
                              placeholder="000"
                              defaultValue={1000}
                              required
                            />
                          </div>
                        </Col> */}
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Quantity <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="quantity"
                              id="quantity"
                              type="number"
                              className="form-control ps-5"
                              placeholder="100"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              What category is your product in? <span className="text-danger">*</span>
                            </Label>
                            <select className="btn btn-icon form-control ps-5 quantity" id="category" style={{width: "100%"}}>
                              <option value={"Gaming"}>Gaming</option>
                              <option value={"Beauty"}>Beauty</option>
                              <option value={"Health"}>Health</option>
                              <option value={"Women's Fashion"}>Women's Fashion</option>
                              <option value={"Men's Fashion"}>Men's Fashion</option>
                              <option value={"Accessories"}>Accessories</option>
                              <option value={"Gym and Fitness"}>Gym and Fitness</option>
                              <option value={"Electronics"}>Electronics</option>
                              <option value={"Others"}>Others</option>
                            </select>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Enter the different product variants you have, separate them with a comma <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="name"
                              id="productTags"
                              type="text"
                              className="form-control ps-5"
                              placeholder="e.g fitness,gym,sport,exercise"
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Does your product have multiple sizes? <span className="text-danger">*</span>
                            </Label>
                            <select className="btn btn-icon form-control ps-5 quantity" id="oneSize" onChange={changeProductVariants} style={{width: "100%"}}>
                              <option key={0} value={""}>No</option>
                              <option key={1} value={true}>Yes</option>
                            </select>
                          </div>
                        </Col>
                        {addMultipleSizes}
                        {(store.collections) && (
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Add to a collection <span className="text-danger">*</span>
                              </Label>
                              <select className="btn btn-icon form-control ps-5 quantity" id="collection" onChange={changeCollection} style={{width: "100%"}}>
                                {store.collections.map(col => {
                                  return(<option value={col}>{col.name}</option>);
                                })}
                              </select>
                            </div>
                        </Col>
                        )}
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Description{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="message-square"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <textarea
                              name="description"
                              id="description"
                              type="text"
                              className="form-control ps-5"
                              placeholder="Describe the product you are selling:"
                              rows={5}
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-center">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="submitBnt btn btn-primary"
                              value="Create product"
                            />
                          </div>
                          <div id="simple-msg"></div>
                          <div onClick={closeModal} className="btn btn-soft-primary ms-2">
                            Close
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Modal isOpen={isOpenNewCollection} toggle={closeModal} centered size="lg">

            <ModalBody className="p-4">
              <Container fluid className="px-0">
                <Row>
                  <h4 className="card-title">Create your Collection!</h4>
                  <h5 className="text-muted">Organise your products by adding them to collections</h5>
                  <div className="custom-form mt-3">
                    <Form
                      method="post"
                      onSubmit={createCollection}
                      name="contact-form"
                      id="contact-form"
                    >
                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Collection Name <span className="text-danger">*</span>
                            </Label>
                            <Input
                              id="collectionName"
                              type="text"
                              className="form-control ps-5"
                              placeholder="Collection Name: e.g Clothes"
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-center">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="submitBnt btn btn-primary"
                              value="Create collection"
                            />
                          </div>
                          <div id="simple-msg"></div>
                          <div onClick={closeModal} className="btn btn-soft-primary ms-2">
                            Close
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Modal isOpen={isOpenImport} toggle={closeModal} centered size="lg">

            <ModalBody className="p-4">
              <Container fluid className="px-0">
                <Row>
                  <h4 className="card-title">Import products by CSV</h4>
                  <h5 className="text-muted">Download a {" "}
                  <a href="https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/files%2Fproduct_template%2Fproduct_template%20-%20Sheet1.csv?alt=media&token=17067b4b-0731-45e3-8bb4-dfd007f06374">
                    sample CSV template</a> to see an example of the format required.</h5>
                  <div className="custom-form mt-3">
                    <Form
                      method="post"
                      onSubmit={importProducts}
                      name="csv-form"
                      id="csv-form"
                    >
                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Select a csv file <span className="text-danger">*</span>
                            </Label>
                            <Input
                              id="csvFile"
                              type="file"
                              accept=".csv"
                              className="form-control ps-5"
                              required
                              onChange={(e) => {setCsvFile(e.target.files[0])}}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-center">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="submitBnt btn btn-primary"
                              value="Upload"
                            />
                          </div>
                          <div id="simple-msg"></div>
                          <div onClick={closeModal} className="btn btn-soft-primary ms-2">
                            Close
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Snackbar open={isSnackbarImportOpen} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Your product have been imported successfully!
            </Alert>
          </Snackbar>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      </Container>
    </section>
    </React.Fragment>
    </ConfirmProvider>
  );
  };
};


export default MyProducts;
