import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Form,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { visuallyHidden } from '@mui/utils';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ButtonGroup from '@mui/material/ButtonGroup';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import Comingsoon from './Comingsoon';

import {fetchAllOrders, fetchMoreOrders} from './getProducts';
import fire from "../Fire.js";
import fire2 from "../Fire2.js";
import { color } from '@mui/system';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function numberSeparator(numb) {
  var str = numb.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
};

function useStore() {
  const [store, setStore] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        setStore(Object.assign(doc.data(), {storeId: doc.id,}));
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

function useProfile() {
  const [profile, setProfile] = React.useState({});

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .onSnapshot(document => {
      setProfile(document.data());
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return profile;
};


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'customer',
    numeric: false,
    disablePadding: false,
    label: 'Customer',
  },
  {
    id: 'orderId',
    numeric: false,
    disablePadding: false,
    label: 'Order id',
  },
  {
    id: 'revenue',
    numeric: false,
    disablePadding: false,
    label: 'Revenue',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'items',
    numeric: false,
    disablePadding: false,
    label: 'Items',
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const Orders = (props) => {
  const user = fire.auth().currentUser;
  const classes = useStyles();
  let history = useHistory();
  const store = useStore();
  const profile = useProfile();
  const [products, setProducts] = React.useState([]);
  const [singleOrder, setSingleOrder] = React.useState({});
  const [isModalOpen, setOpenModal] = React.useState(false);
  const [timestamp, setTimestamp] = React.useState("");

  const [searchField, setSearchField] = React.useState("");
  const [myFilter, setMyFilter] = React.useState("All");

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('productName');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [orders, setOrders] = React.useState([]);
  const [startAfter, setStartAfter] = React.useState();
  const [ordersPerLoad] = React.useState(1000);
  const [lastOrder, setLastOrder] = React.useState(false);
  const [start, setStart] = React.useState();
  const [end, setEnd] = React.useState();

  const [orderedItems, setOrderedItems] = React.useState(0);
  const [ordersLength, setOrdersLength] = React.useState(0);
  const [fulfilledOrders, setFulfilledOrders] = React.useState(0);
  const periods = ["Today", "This week", "This month", "This quarter", "This year", "Last year"];
  const [period, setPeriod] = React.useState(null);

  const [ready, setReady] = React.useState(false);

  var totalRevenue = 0;

  React.useEffect(() => {
    const rightNow = new Date(Date.now());
    const todayDate = new Date().getDate();
    const formattedTodayDate = ("0" + todayDate).slice(-2);
    const thisMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
    const thisYear = new Date().getFullYear();
    const today = new Date(thisYear+"-"+thisMonth+"-"+formattedTodayDate);
    const startMonth = new Date(thisYear+"-"+thisMonth+"-01");
    const startYear = new Date(thisYear+"-01-01");
    const startAt = startMonth;
    const endAt = rightNow;

    var thirtyDaysAgo = today;
    thirtyDaysAgo.setDate(today.getDate() - 30);
    const unsubscribe = fire
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("stores")
      .get()
      .then(stores => {
        stores.forEach(store =>{
          setOrders([]);
          setStartAfter();
          setLastOrder(false);
          setStart(startAt);
          setEnd(endAt);
          getOrders(store.id, startAt, endAt);
        });
      });

    return unsubscribe;
  }, []);

  async function getOrders(storeId, startAt, endAt) {
    // const ordersData = await fetchAllOrders({ storeId: storeId, userUid: user.uid, startAt: startAt, endAt: endAt});
    // setOrders([...ordersData.orders]);
    // setStartAfter(ordersData.lastVisible);
    // ordersData.orders.length == 0 ? setLastOrder(true) : setLastOrder(false);

    
    let totalItems = 0;
    var totalOrders = 0;
    var totalFulfilledOrders = 0;

    // const x = ordersData.orders.filter(
    //   (order) => 
    //   (
    //     (order.status === "Fulfilled" )
    //   )
    // );
    // ordersData.orders.forEach(order => {
    //   order.products.forEach(product => {
    //     totalItems += 1;
    //   })
    // });
    // setOrdersLength(ordersData.orders.length);
    // setFulfilledOrders(x.length);
    
    // setOrderedItems(totalItems);

    await fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(storeId)
    .collection("orders")
    .where('timestamp', '>', startAt)
    .where('timestamp', '<', endAt)
    .orderBy('timestamp', 'desc')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((order) => {
        const products = [];
        totalOrders += 1;
        if (order.data().status === "Fulfilled") {
          totalFulfilledOrders += 1;
        }
        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("stores")
        .doc(storeId)
        .collection("orders")
        .doc(order.id)
        .collection("products")
        .get()
        .then(productsOrdered => {
          productsOrdered.forEach(productOrdered => {
            products.push(productOrdered.data());
          });
          setOrders(currentOrders => [...currentOrders, Object.assign(order.data(), { products: products, orderId: order.id})]);
          console.log(products.length);
          setOrderedItems(orderedItems + products.length)
        });
      });
    });

    setOrdersLength(totalOrders);
    setFulfilledOrders(totalFulfilledOrders);
    //setOrderedItems(totalItems);

  };

  async function getMoreOrders(startAt, endAt) {
    if (!lastOrder) {
      const ordersData = await fetchMoreOrders({ordersPerLoad, startAfter, storeId: store.storeId, userUid: user.uid, startAt: startAt, endAt: endAt});
      setOrders([...orders, ...ordersData.orders]);
      setStartAfter(ordersData.lastVisible);
      ordersData.orders.length == 0 ? setLastOrder(true) : setLastOrder(false); 
    };
  };

  const search = (products) => {
    const x = products.filter(
      (order) => 
      (
        (order.customerFirstName.toLowerCase().indexOf(searchField) > -1 || order.customerLastName.toLowerCase().indexOf(searchField) > -1) &&
        (order.status === myFilter || myFilter === "All") &&
        (order.orderId !== "--stats--")
      )
    );
    return x;
  };

  const markAsFulfilled = () => {
    selected.forEach(order => {
      fire.firestore().collection("users").doc(order.storeUserUid).collection("stores").doc(order.storeId).collection("orders").doc(order.orderId).update({
        status: "Fulfilled",
      });
      fire.firestore().collection("orders").doc(order.orderId).update({
        status: "Fulfilled",
      });
      order.products.forEach(product => {
        fire.firestore().collection("users").doc(user.uid).collection("stores").doc(product.storeId).collection("orders").doc(order.orderId).update({
          status: "Fulfilled",
        });
        fire.firestore().collection("users").doc(order.storeUserUid).collection("stores").doc(order.storeId).collection("orders").doc(order.orderId).collection("products").doc(product.productId).update({
          status: "Fulfilled",
        });
        fire.firestore().collection("users").doc(user.uid).collection("stores").doc(product.storeId).collection("orders").doc(order.orderId).collection("products").doc(product.productId).update({
          status: "Fulfilled",
        });
        fire.firestore().collection("orders").doc(order.orderId).collection("products").doc(product.productId).update({
          status: "Fulfilled",
        });
        const deliveryConfirm = fire.functions().httpsCallable('deliveryConfirm');
        deliveryConfirm({orderId: order.orderId, customerEmail: order.customerEmail, storeEmail: order.storeEmail, storeName: order.storeName, merchantEmail: user.email, merchantName: store.storeName, name: product.name, customerFirstName: order.customerFirstName, items: product.items, picUrl: product.picUrl, deliveryAddress: order.deliveryAddress, logisticsCompany: "", deliveryDays: "", message: ""}).then(result => {
          console.log(result.data);
        });
        const updateOrderStatus = fire2.functions().httpsCallable('updateOrderStatus');
        updateOrderStatus({orderId: order.orderId, customerEmail: order.customerEmail, status: "Fulfilled", productId: product.productId}).then(result => {
          console.log(result.data);
        });
      });

      sendPushNotification({expoPushToken: order.storePushToken, title: "Delivery Confirmation", body: "["+store.storeName + "] has dispatched "+order.customerFirstName+"'s order."});
      

    });
    getOrders(store.storeId, start, end);
    setSelected([]);
  };

  const handleAlignment = (event, newAlignment) => {
    setMyFilter(newAlignment);
  };

  const openModal = (e) => {
    setOpenModal(true);
    setProducts(e.products);
    setSingleOrder(e);
    setTimestamp(e.timestamp.toDate().toDateString());
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const confirmOrder = (e) => {
    fire.firestore().collection("users").doc(user.uid).collection("stores").doc(e.storeId).collection("orders").doc(singleOrder.orderId).collection("products").doc(e.productId).update({
      status: "unfulfilled",
    });
    fire.firestore().collection("users").doc(singleOrder.storeUserUid).collection("stores").doc(singleOrder.storeId).collection("orders").doc(singleOrder.orderId).collection("products").doc(e.productId).update({
      status: "unfulfilled",
    });
    fire.firestore().collection("users").doc(user.uid).collection("stores").doc(e.storeId).collection("orders").doc(singleOrder.orderId).update({
      status: "unfulfilled",
    });
    fire.firestore().collection("users").doc(singleOrder.storeUserUid).collection("stores").doc(singleOrder.storeId).collection("orders").doc(singleOrder.orderId).update({
      status: "unfulfilled",
    });

    fire.firestore().collection("orders").doc(singleOrder.orderId).collection("products").doc(e.productId).update({
      status: "unfulfilled",
    });
    fire.firestore().collection("orders").doc(singleOrder.orderId).update({
      status: "unfulfilled",
    });
    setOpenModal(false);
    getOrders(store.storeId, start, end);
    sendPushNotification({expoPushToken: singleOrder.storePushToken, title: "Order Confirmation", body: "["+store.storeName + '] has accepted your order for the product: '+ e.name});
  };

  const confirmDelivery = (e) => {
    const logisticsCompany = document.getElementById(e.productId+"logisticsCompany").value;
    const deliveryDays = document.getElementById(e.productId+"deliveryDays").value;
    const message = document.getElementById(e.productId+"message").value;
    fire.firestore().collection("users").doc(singleOrder.storeUserUid).collection("stores").doc(singleOrder.storeId).collection("orders").doc(singleOrder.orderId).collection("products").doc(e.productId).update({
      status: "Fulfilled",
    });
    fire.firestore().collection("users").doc(user.uid).collection("stores").doc(e.storeId).collection("orders").doc(singleOrder.orderId).collection("products").doc(e.productId).update({
      status: "Fulfilled",
    });
    fire.firestore().collection("users").doc(singleOrder.storeUserUid).collection("stores").doc(singleOrder.storeId).collection("orders").doc(singleOrder.orderId).update({
      status: "Fulfilled",
    });
    fire.firestore().collection("users").doc(user.uid).collection("stores").doc(e.storeId).collection("orders").doc(singleOrder.orderId).update({
      status: "Fulfilled",
    });

    fire.firestore().collection("orders").doc(singleOrder.orderId).collection("products").doc(e.productId).update({
      status: "Fulfilled",
    });
    fire.firestore().collection("orders").doc(singleOrder.orderId).update({
      status: "Fulfilled",
    });
    setOpenModal(false);
    getOrders(store.storeId, start, end);

    const deliveryConfirm = fire.functions().httpsCallable('deliveryConfirm');
    deliveryConfirm({orderId: singleOrder.orderId, customerEmail: singleOrder.customerEmail, storeEmail: singleOrder.storeEmail, storeName: singleOrder.storeName, merchantEmail: user.email, merchantName: store.storeName, name: e.name, customerFirstName: singleOrder.customerFirstName, items: e.items, picUrl: e.picUrl, deliveryAddress: singleOrder.deliveryAddress, logisticsCompany: logisticsCompany, deliveryDays: deliveryDays, message: message,}).then(result => {
      console.log(result.data);
    });
    sendPushNotification({expoPushToken: singleOrder.storePushToken, title: "Delivery Confirmation", body: "["+store.storeName + "] has dispatched "+order.customerFirstName+"'s order. The product should be delivered within "+ deliveryDays+ " days"});
    
    const updateOrderStatus = fire2.functions().httpsCallable('updateOrderStatus');
    updateOrderStatus({orderId: singleOrder.orderId, customerEmail: singleOrder.customerEmail, status: "Fulfilled", productId: e.productId}).then(result => {
      console.log(result.data);
    });
  };

  const changePeriod = (event) => {
    setPeriod(event.target.value);
    const selectedPeriod = event.target.value;

    const rightNow = new Date(Date.now());
    const todayDate = new Date().getDate();
    const formattedTodayDate = ("0" + todayDate).slice(-2);
    const thisMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
    const thisYear = new Date().getFullYear();
    const today = new Date(thisYear+"-"+thisMonth+"-"+formattedTodayDate);
    const startMonth = new Date(thisYear+"-"+thisMonth+"-01");
    const startYear = new Date(thisYear+"-01-01");
    const startLastYear = new Date((thisYear-1)+"-01-01");
    const endLastYear = new Date(thisYear+"-01-01");

    if (selectedPeriod === "Today"){
      const startAt = today;
      const endAt = rightNow;
      getOrders(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "This week") {
      const currentDay = new Date().getDay();
      var startAt = today;
      startAt.setDate(today.getDate() - currentDay);
      const endAt = rightNow;
      setStart(startAt);
      setEnd(endAt);
      getOrders(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "This month") {
      const startAt = startMonth;
      const endAt = rightNow;
      setStart(startAt);
      setEnd(endAt);
      getOrders(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "This quarter") {
      if (thisMonth === "01" || thisMonth === "02" || thisMonth === "03"){
        const startQuarter = new Date(thisYear+"-01-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getOrders(store.storeId, startAt, endAt );
      } else if (thisMonth === "04" || thisMonth === "05" || thisMonth === "06"){
        const startQuarter = new Date(thisYear+"-04-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getOrders(store.storeId, startAt, endAt );
      } else if (thisMonth === "07" || thisMonth === "08" || thisMonth === "09"){
        const startQuarter = new Date(thisYear+"-07-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getOrders(store.storeId, startAt, endAt );
      } else if (thisMonth === "10" || thisMonth === "11" || thisMonth === "12"){
        const startQuarter = new Date(thisYear+"-10-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getOrders(store.storeId, startAt, endAt );
      };
    } else if (selectedPeriod === "This year") {
      const startAt = startYear;
      const endAt = rightNow;
      setStart(startAt);
      setEnd(endAt);
      getOrders(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "Last year") {
      const startAt = startLastYear;
      const endAt = endLastYear;
      setStart(startAt);
      setEnd(endAt);
      getOrders(store.storeId, startAt, endAt );
    }
  };

  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Orders
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button onClick={markAsFulfilled}  style={{color:'green'}}>Mark as fulfilled</Button>
          </ButtonGroup>
        ) : (
        <>
        </>
        )}
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = search(orders).map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(orders).length) : 0;

  if(profile.verified !== true){
    return(<Comingsoon/>);
  } else {
  return (
    <React.Fragment  >
    <section className="section" style={{width: "100%", height: '100%', minHeight: "100vh", flex: 1, backgroundColor: '#f2f8fb',}}>
    <Container>
    <div>
      <div style={{ backgroundColor: '#10041c', width: '100%', borderRadius: 30}}>
      <Row direction="row" >
        <Col style={{padding: 20}}>
          <FormControl fullWidth style={{color: '#fff'}}>
            <InputLabel id="demo-simple-select-label" style={{color: '#fff'}}>
              {(period === null) && (
                <>This Month</>
              )}
              {(period !== null) && (
                <>Period</>
              )}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={period}
              onChange={changePeriod}
              label="This Month"
              defaultValue='This Month'
              style={{color: '#fff'}}
            >
              {periods.map(eachPeriod => {
                return(
                  <MenuItem value={eachPeriod}>{eachPeriod}</MenuItem>
                )
              })}
          </Select>
          </FormControl>
        </Col>
        <Col style={{padding: 20,}}>
          <Typography style={{color: '#fff'}}>Orders</Typography>
          <Typography style={{color: '#fff'}}>{ordersLength}</Typography>
        </Col>
        <Col style={{padding: 20,}}>
          <Typography style={{color: '#fff'}}>Ordered items</Typography>
          <Typography style={{color: '#fff'}}>{orderedItems}</Typography>
        </Col>
        <Col style={{padding: 20,}}>
          <Typography style={{color: '#fff'}}>Fulfilled orders</Typography>
          <Typography style={{color: '#fff'}}>{fulfilledOrders}</Typography>
        </Col>
      </Row>
      </div>
      <Paper sx={{ width: '100%', mb: 2, mt: 2 }}>
        <ToggleButtonGroup
          value={myFilter}
          color="primary"
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
        >
          <ToggleButton value="All" aria-label="All">
            <Typography>All</Typography>
          </ToggleButton>
          <ToggleButton value="unfulfilled" aria-label="All">
            <Typography>Unfulfilled</Typography>
          </ToggleButton>
          <ToggleButton value="Fulfilled" aria-label="All">
            <Typography>Fulfilled</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
        <Form
          role="search"
          method="get"
          id="searchform"
        >
          <div className="input-group mb-3 border rounded">
            <button type="submit" onClick={(e) => e.preventDefault()} className="input-group-text bg-white border-0" id="searchsubmit"><i className="uil uil-search"></i></button>
            <Input
              type="text"
              className="form-control border-0"
              name="s"
              id="search"
              placeholder="Search customers"
              onChange={(e) => setSearchField(e.target.value.toLowerCase())}
            />
          </div>
        </Form>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={search(orders).length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(search(orders), getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let date;

                  var revenue = 0;
                  row.products.forEach(product => {
                    if(product.storeId === store.storeId){
                      revenue = revenue + Number(product.cost);
                    } else {
                      revenue = row.revenue;
                    };
                  });
                  date = row.timestamp.toDate().toDateString();

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.productId}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          onClick={(event) => handleClick(event, row)}
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        onClick={() => openModal(row)}
                        id={labelId}
                        scope="row"
                        align="left"
                        component="th"
                      >
                        {row.customerFirstName} {row.customerLastName}
                      </TableCell>
                      <TableCell align="left" onClick={() => openModal(row)}>{row.orderId}</TableCell>
                      <TableCell align="left" onClick={() => openModal(row)}>NGN {numberSeparator(revenue)}</TableCell>
                      <TableCell align="left" onClick={() => openModal(row)}>{row.status}</TableCell>
                      <TableCell align="left" onClick={() => openModal(row)}>{(row.products).length}</TableCell>
                      <TableCell align="left" onClick={() => openModal(row)}>{date}</TableCell>
                      
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={search(orders).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Modal isOpen={isModalOpen} toggle={closeModal} centered size="lg">
        <ModalHeader toggle={closeModal}>Products</ModalHeader>
        <div onClick={closeModal} className="btn btn-soft-primary ms-2">
          Close
        </div>
        <ModalBody className="p-4">
        <h5 className="mt-4">Customer details :</h5>
        <p className="text-muted">Name: {singleOrder.customerFirstName} {singleOrder.customerLastName}</p>
        <p className="text-muted">Email: {singleOrder.customerEmail} </p>
        <p className="text-muted">PhoneNumber: {singleOrder.customerPhoneNumber} </p>
        <p className="text-muted">Delivery Address: {singleOrder.deliveryAddress} </p>
        <p className="text-muted">Date: {timestamp} </p>

        <h5 className="mt-4">Delivery fee: NGN {numberSeparator(Number(singleOrder.deliveryFee))}</h5>
        

        {products.map((product, key) =>  {
          if (product.storeId === store.storeId) {
            let feePercentage = 5;
            if (singleOrder.feePercentage) {
              feePercentage = Number(singleOrder.feePercentage);
            }
            var feeMultiple = (feePercentage)/100;

            var revenue = (product.price * product.items);
            var fee = revenue * feeMultiple;
            var profits = revenue - fee;
            return (
              <Container fluid className="px-0">
              <Row>
                <Col lg={5}>
                  <div className="tiny-single-item">
                    <div className="tiny-slide"><img src={product.picUrl} className="img-fluid rounded" alt="" /></div>
                  </div>
                </Col>

                <Col lg={7} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                  <h4 className="title">{product.name}</h4>
                  <h5 className="mt-4">Revenue:</h5>
                  <h5 className="text-muted">NGN {numberSeparator(revenue)}</h5>
                  <h5 className="mt-4">Fee:</h5>
                  <h5 className="text-muted">NGN {numberSeparator(fee)}</h5>
                  <h5 className="mt-4">Profits:</h5>
                  <h5 className="text-muted">NGN {numberSeparator(profits)}</h5>
                  <h5 className="mt-4">Quantity:</h5>
                  <h5 className="text-muted">{product.items}</h5>
                  {product.multipleSizes &&(
                    <div>
                      <h5 className="mt-4">Variant:</h5>
                      <h5 className="text-muted">{product.size}</h5>
                    </div>
                  )}
                  <h5 className="mt-4">Status:</h5>
                  <h5 className="text-muted">{product.status}</h5>
                  {(product.status === "Pending confirmation") &&(
                    <Button variant="contained" color="primary" onClick={() => confirmOrder(product)}>Confirm order</Button>
                  )}
                  {(product.status === "unfulfilled") &&(
                    <div>
                    <h5 className="text-muted">Have you delivered the product?</h5>
                      <Col lg="12">
                      <div className="mb-3">
                        <Label className="form-label">What logistics company is delivering the product :</Label>
                        <Input
                          id={product.productId+"logisticsCompany"}
                          type="text"
                          className="form-control ps-5"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="mb-3">
                        <Label className="form-label">How many days will it take to deliver the product :</Label>
                        <Input
                          id={product.productId+"deliveryDays"}
                          type="number"
                          className="form-control ps-5"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="mb-3">
                        <Label className="form-label">Any other information the customer will need to recieve the product :</Label>
                        <textarea
                          id={product.productId+"message"}
                          type="text"
                          className="form-control ps-5"
                          rows={3}
                          required
                        />
                      </div>
                    </Col>
                      <Button variant="contained" color="primary" onClick={() => confirmDelivery(product)}>Notify customer</Button>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
           );
          } else {
            let maxPrice = 0;
            let discountGiven = false;
            let percentageDiscountGiven = 0;
            let multiple = 1;
            let feePercentage = 5;
            if (product.price) {
              maxPrice = product.price;
            }
            if (product.maxPrice) {
              maxPrice = product.maxPrice;
            }
            if (singleOrder.discountGiven === true){
              discountGiven = singleOrder.discountGiven;
              percentageDiscountGiven = singleOrder.percentageDiscountGiven;
              multiple = Number((100-Number(singleOrder.percentageDiscountGiven))/100);
            }

            if (singleOrder.feePercentage) {
              feePercentage = Number(order.feePercentage);
            }
            var revenue = (maxPrice * product.items * multiple);
            var productCosts = (product.price * product.items);
            var feeMultiple = (feePercentage)/100;
            var fee = ((maxPrice * product.items * multiple) - (product.price * product.items)) *feeMultiple;
            var profits = ((maxPrice * product.items * multiple) - (product.price * product.items)) - fee;
            return (
              <Container fluid className="px-0">
              <Row>
                <Col lg={5}>
                  <div className="tiny-single-item">
                    <div className="tiny-slide"><img src={product.picUrl} className="img-fluid rounded" alt="" /></div>
                  </div>
                </Col>

                <Col lg={7} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                  <h4 className="title">{product.name}</h4>
                  <p className="text-muted">Brand Email: {product.email} </p>
                  <p className="text-muted">Contact brand for shipping updates </p>
                  <h5 className="mt-4">Revenue:</h5>
                  <h5 className="text-muted">NGN {numberSeparator(revenue)}</h5>
                  <h5 className="mt-4">Product costs:</h5>
                  <h5 className="text-muted">NGN {numberSeparator(productCosts)}</h5>
                  <h5 className="mt-4">Fee:</h5>
                  <h5 className="text-muted">NGN {numberSeparator(fee)}</h5>
                  <h5 className="mt-4">Profits:</h5>
                  <h5 className="text-muted">NGN {numberSeparator(profits)}</h5>
                  <h5 className="mt-4">Quantity:</h5>
                  <h5 className="text-muted">{product.items}</h5>
                  {product.multipleSizes &&(
                    <div>
                      <h5 className="mt-4">Variant:</h5>
                      <h5 className="text-muted">{product.size}</h5>
                    </div>
                  )}
                  <h5 className="mt-4">Status:</h5>
                  <h5 className="text-muted">{product.status}</h5>
                </Col>
              </Row>
            </Container>
            );
          };
          })}
          
        </ModalBody>
      </Modal>
    </div>
    </Container>
    </section>
    </React.Fragment>
  );
  };
}

export default Orders;

async function sendPushNotification({expoPushToken, title, body}) {
  const message = {
    to: expoPushToken,
    sound: 'default',
    title: title,
    body: body,
    data: { someData: 'goes here' },
  };

  await fetch('https://exp.host/--/api/v2/push/send', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Accept-encoding': 'gzip, deflate',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message),
  });
};