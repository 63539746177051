import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database'
import 'firebase/firestore';
import 'firebase/functions';
// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyC2G0eWDN1HH-g4eFu-OEm-BjzDODTq-r8",
  authDomain: "swhag-shoppers.firebaseapp.com",
  projectId: "swhag-shoppers",
  storageBucket: "swhag-shoppers.appspot.com",
  messagingSenderId: "807747194259",
  appId: "1:807747194259:web:67e152bc26b8e65267ac66",
  measurementId: "G-SSHZ613G75"
};
// Initialize Firebase

const fire2 = firebase.initializeApp(firebaseConfig, "secondary");

export default fire2;
